import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TrendingEpisodeInterface } from "../types";

interface TrendingEpisodesState {
  episodes: TrendingEpisodeInterface[];
}

// TODO: is there any way to not nest this under episodes? Just have `trendingEpisodes` be an array?
const initialState: TrendingEpisodesState = {
  episodes: [],
};

export const trendingEpisodesSlice = createSlice({
  name: "trendingEpisodes",
  initialState,
  reducers: {
    addTrendingEpisodes: (
      state,
      action: PayloadAction<TrendingEpisodeInterface[]>
    ) => {
      return { episodes: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { addTrendingEpisodes } = trendingEpisodesSlice.actions;

export default trendingEpisodesSlice.reducer;
