import { Loading } from "./basic/Loading";
import PodcastList from "./PodcastList";
import React from "react";
import { RootState } from "../store";
import SearchBar from "./SearchBar";
import TrendingEpisodes from "./TrendingEpisodes";
import useSearchPodcastByName from "../effects/useSearchPodcastByName";
import { useSelector } from "react-redux";

const Homepage: React.FunctionComponent = () => {
  const podcastQuery = useSelector(
    (state: RootState) => state.queries.podcastQuery
  );
  const searchQuery = podcastQuery.length ? podcastQuery.trim() : "";

  const { podcasts, error, isLoading } = useSearchPodcastByName(searchQuery);

  const renderTrendingEpisodes = () => <TrendingEpisodes />;

  const renderSearchResults = () => {
    if (isLoading) return <Loading />;
    if (error) return JSON.stringify(error);

    return <PodcastList podcasts={podcasts} />;
  };

  const renderTrendingEpisodesOrSearchResults = () => {
    return podcastQuery?.trim().length > 2
      ? renderSearchResults()
      : renderTrendingEpisodes();
  };

  return (
    <div>
      <SearchBar />
      <div>{renderTrendingEpisodesOrSearchResults()}</div>
    </div>
  );
};

export default Homepage;
