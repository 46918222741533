import { LoadingCircle } from "./basic/Loading";
import React from "react";
import { UserInterface } from "../types";
import { clearPodcastQuery } from "../features/querySlice";
import logo from "../assets/images/logo_record.png";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const HeadingWrapper = styled.div`
  padding: 1rem 0 2rem 0;

  @media only screen and (max-width: 768px) {
    padding: 0.5rem 0 1.5rem 0;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Description = styled.span`
  font-size: 1rem;
  font-weight: 300;
  padding: 0.3rem 0;

  @media only screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const LogoWrapper = styled.div`
  text-decoration: none;
  display: flex;

  &:hover {
    cursor: pointer;
  }
`;

const LogoImage = styled.img`
  height: 2rem;
  margin-right: 0.7rem;
`;

const Title = styled.h3`
  font-size: 2rem;
  color: black;
  margin: 0;
`;

const AccountIcon = styled.span`
  font-size: 2rem;
  padding-left: 1rem;

  &.lnr-enter-down {
    transform: rotate(-90deg);
    padding: 0;
    display: block;
  }

  &:hover {
    cursor: pointer;
  }
`;

const AdminAccountIcon = styled(AccountIcon)`
  color: ${({ theme }) => theme.colors.orange};
  font-size: 2.1rem;
`;

type HeaderProps = {
  user: UserInterface | null;
  isLoading: boolean;
};

const Header: React.FunctionComponent<HeaderProps> = ({ user, isLoading }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const navigateToHome = () => {
    dispatch(clearPodcastQuery());
    history.push(`/`);
  };

  const navigateToAccount = () => history.push("/signup");
  const navigateToAdmin = () => history.push("/admin");

  const renderUserLink = () => {
    const icon = user === null ? "lnr-enter-down" : "lnr-user";

    if (isLoading) {
      return <LoadingCircle narrow />;
    }
    return (
      <AccountIcon
        className={`lnr ${icon}`}
        onClick={() => navigateToAccount()}
      ></AccountIcon>
    );
  };

  const renderAdminLink = () => {
    const currentUserIsAdmin = user !== null && user.admin;

    if (!currentUserIsAdmin) {
      return null;
    }
    return (
      <AdminAccountIcon
        className="lnr lnr-laptop-phone"
        onClick={() => navigateToAdmin()}
      ></AdminAccountIcon>
    );
  };

  return (
    <HeadingWrapper>
      <Row>
        <LogoWrapper onClick={() => navigateToHome()}>
          <LogoImage src={logo} alt="gemfm logo" />
          <Title>GemFM</Title>
        </LogoWrapper>
        <div>
          {renderAdminLink()}
          {renderUserLink()}
        </div>
      </Row>
      <Row>
        <Description>Discover your next favorite podcast episode</Description>
      </Row>
    </HeadingWrapper>
  );
};

export default Header;
