export const theme = {
  colors: {
    black: "#2d2d2d",
    gray: "#757575",
    grayLight: "#b4b4b4",
    grayLightest: "#e4e4e4",

    // base colors
    white: "#F7F8F2",
    red: "#931F1D",
    green: "#406359",
    olive: "#709176",
    blue: "#3692D3",
    yellow: "#FFD166",

    // shades
    whiteBright: "#ffffff;",
    redLight: "#dd4848",
    orange: "#ffa737",
    orangeLight: "#fff4df",
    orangeDark: "#ff661b",
    greenHappy: "#42bf42",
    greenBright: "#398743",
    greenLight: "#c9e1cd",
  },
  borders: {
    lightGray: `1px solid #e4e4e4`,
  },
  boxShadow: {
    standard: `0px 2px 3px 0px rgb(172, 173, 166, 40%);`,
  },
};
