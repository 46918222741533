import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface Search {
  [key: string]: number[];
}

const initialState: Search = {};

export const searchSlice = createSlice({
  name: "searches",
  initialState,
  reducers: {
    addSearch: (
      state,
      action: PayloadAction<{
        query: string;
        podcastIds: number[];
      }>
    ) => {
      state[action.payload.query] = action.payload.podcastIds;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addSearch } = searchSlice.actions;

export default searchSlice.reducer;
