import { ErrorCard } from "./basic/Card";
import FullCenteredPage from "./basic/FullCenteredPage";
import React from "react";
import { SubmitButton } from "./basic/Button";
import { useHistory } from "react-router-dom";

const TokenError: React.FunctionComponent = () => {
  const history = useHistory();

  const navigateTo = (url: string) => {
    history.push(url);
  };

  return (
    <FullCenteredPage>
      <ErrorCard>
        <h2>Whoops...</h2>
        <p>Looks like this token is expired or incorrect.</p>
        <br />
        <SubmitButton onClick={() => navigateTo("/login")}>
          Back to login
        </SubmitButton>
      </ErrorCard>
    </FullCenteredPage>
  );
};

export default TokenError;
