import styled from "styled-components";

export const Bullet = styled.span`
  padding: 0 0.5rem;
  color: ${({ theme }) => theme.colors.olive};
  :before {
    content: "•";
  }
`;

export const VerticalDivider = styled(Bullet)`
  :before {
    content: "|";
  }
`;
