import { TagColors } from "../TagColors";
import styled from "styled-components";

interface TagProps {
  selected: boolean;
  color: string;
}

export const Tag = styled.div<TagProps>`
  margin: 0 0.8rem 0.8rem 0;
  border-radius: 0.3rem;
  padding: 0.2rem 0.5rem;
  box-sizing: border-box;
  border: ${(props) =>
    props.selected ? "2px solid #000000" : "2px solid #ffffff"};
  user-select: none;

  display: flex;
  align-items: center;

  font-size: 0.85rem;

  box-shadow: 1px 1px 4px 0px rgb(0, 0, 0, 0.4);
  background: ${(props) => TagColors[props.color].background};
  color: ${(props) => TagColors[props.color].textColor};

  &:hover {
    cursor: pointer;
  }
`;

export const TagWithCount = styled(Tag)`
  padding: 0;
  padding-left: 0.5rem;
  font-size: 0.75rem;
  border: none;

  &:hover {
    cursor: auto;
  }
`;

export const TagCount = styled.div`
  border-left: 1px solid white;
  padding: 0.2rem 0.5rem;
  margin-left: 0.5rem;
`;
