import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../store";
import { TagInterface } from "../types";
import { saveTags } from "../features/tagsSlice";

interface TagsEffectInterface {
  tags: TagInterface[];
  error: string | null;
  isLoading: boolean;
}

export default function useTags(): TagsEffectInterface {
  const dispatch = useDispatch();
  const tags = useSelector((state: RootState) => state.tags);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // TODO: standardize how we check loading & data from redux on each effect/slice

  useEffect(() => {
    if (!tags.length) {
      fetch(`/api/v1/tags`)
        .then((res) => res.json())
        .then((res) => {
          setIsLoading(false);
          dispatch(saveTags(res.tags));
        })
        .catch((error) => {
          setError(error);
          console.log("Error fetching: ", error);
        });
    } else {
      setIsLoading(false);
    }
  }, [tags.length]);

  return {
    isLoading,
    error,
    tags,
  };
}
