import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PodcastInterface, PodcastSliceInterface } from "../types";

const initialState: PodcastSliceInterface = {};

export const podcastsSlice = createSlice({
  name: "podcasts",
  initialState,
  reducers: {
    addPodcast: (state, action: PayloadAction<PodcastInterface>) => {
      const podcast = action.payload;
      state[Number(podcast.id)] = podcast;
      return state;
    },
    addPodcasts: (state, action: PayloadAction<PodcastInterface[]>) => {
      for (const entry in action.payload) {
        const podcast = action.payload[entry];
        state[Number(podcast.id)] = podcast;
      }

      return state;
    },
  },
});

export const { addPodcast, addPodcasts } = podcastsSlice.actions;
export default podcastsSlice.reducer;
