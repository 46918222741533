import { darken } from "polished";

const DARKEN_AMOUNT = 0.5;

type Colors = {
  background: string;
  textColor: string;
};

type TagColors = { [index: string]: Colors };

export const TagColors: TagColors = {
  1: {
    background: "#ffcc81",
    textColor: darken(DARKEN_AMOUNT, "#ffcc81"),
  },
  2: {
    background: "#84e084",
    textColor: darken(DARKEN_AMOUNT, "#84e084"),
  },
  3: {
    background: "#77b8ff",
    textColor: darken(DARKEN_AMOUNT, "#77b8ff"),
  },
  4: {
    background: "#ffa8c4",
    textColor: darken(DARKEN_AMOUNT, "#f37da2"),
  },
  5: {
    background: "#ecec4b",
    textColor: darken(DARKEN_AMOUNT, "#ecec4b"),
  },
  6: {
    background: "#ccb99b",
    textColor: darken(DARKEN_AMOUNT, "#ccb99b"),
  },
  7: {
    background: "#dcaef3",
    textColor: darken(DARKEN_AMOUNT, "#dcaef3"),
  },
};
