import { configureStore } from "@reduxjs/toolkit";
import episodesReducer from "./features/episodesSlice";
import podcastsReducer from "./features/podcastsSlice";
import queryReducer from "./features/querySlice";
import reviewFormReducer from "./features/reviewFormSlice";
import searchReducer from "./features/searchSlice";
import sessionReducer from "./features/sessionSlice";
import tagsReducer from "./features/tagsSlice";
import trendingEpisodesReducer from "./features/trendingEpisodesSlice";

export const store = configureStore({
  reducer: {
    session: sessionReducer,
    trendingEpisodes: trendingEpisodesReducer,
    queries: queryReducer,
    podcasts: podcastsReducer,
    episodes: episodesReducer,
    searches: searchReducer,
    reviewForm: reviewFormReducer,
    tags: tagsReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
