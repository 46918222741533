import styled from "styled-components";

const FullCenteredPage = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;

  margin-top: 15vh;
`;

export default FullCenteredPage;
