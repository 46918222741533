import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Account } from "./components/Account";
import Admin from "./components/Admin";
import { EpisodePage } from "./components/EpisodePage";
import Footer from "./components/Footer";
import { FullPageContainer } from "./components/basic/Container";
import GlobalStylesheet from "./assets/stylesheets/GlobalStylesheet";
import Header from "./components/Header";
import Homepage from "./components/Homepage";
import PageNotFound from "./components/PageNotFound";
import { PodcastPage } from "./components/PodcastPage";
import React from "react";
import { ThemeProvider } from "styled-components";
import TokenError from "./components/TokenError";
import { theme } from "./assets/stylesheets/theme";
import useSession from "./effects/useSession";

export const App: React.FunctionComponent = () => {
  const { session, error, isLoading } = useSession();

  if (error) {
    console.log(error);
  }

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div className="App">
          <GlobalStylesheet theme={theme} />
          <FullPageContainer>
            {<Header user={session.user} isLoading={isLoading} />}

            <Switch>
              <Route exact path="/" render={() => <Homepage />} />
              <Route
                path="/podcast/:podcastId/:episodeId"
                render={(props) => <EpisodePage {...props} />}
              />
              <Route
                path="/podcast/:podcastId"
                render={(props) => <PodcastPage {...props} />}
              />
              <Route
                path="/login"
                render={() => (
                  <Account
                    user={session.user}
                    serverDomain={session.serverDomain}
                    action="login"
                  />
                )}
              />
              <Route
                path="/signup"
                render={() => (
                  <Account
                    user={session.user}
                    serverDomain={session.serverDomain}
                    action="signup"
                  />
                )}
              />

              <Route path="/token-error" render={() => <TokenError />} />
              <Route path="/admin" render={() => <Admin />} />

              <Route path="*" render={() => <PageNotFound />} />
            </Switch>
          </FullPageContainer>
          <Footer />
        </div>
      </ThemeProvider>
    </Router>
  );
};

export default App;
