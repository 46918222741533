import { EpisodeInterface, PodcastInterface, SortOptions } from "../types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Episode from "./Episode";
import { EpisodeFilter } from "./EpisodeFilter";
import { EpisodeNavigator } from "./EpisodeNavigator";
import { LoadingCircle } from "./basic/Loading";
import { RootState } from "../store";
import { SortBySelector } from "./SortBySelector";
import { slugify } from "../utils/string";
import styled from "styled-components";
import { updateEpisodeFilter } from "../features/querySlice";

type EpisodeListProps = {
  episodes: EpisodeInterface[] | [];
  podcast: PodcastInterface;
  moreEpisodesToLoad: boolean;
};

const LoadingWrapper = styled.div`
  padding: 0.5rem 0.3rem 0.3rem 0.3rem;
  border: 1px solid #f1f1f1;
  border-radius: 0.3rem;
  margin-bottom: 1rem;
`;

const LoadingMessage = styled.p`
  text-align: center;
  font-size: 0.8rem;
  padding-top: 0.5rem;
`;

const EpisodeList: React.FunctionComponent<EpisodeListProps> = ({
  episodes = [],
  podcast,
  moreEpisodesToLoad,
}) => {
  const EPISODES_PER_PAGE = 25;
  const [displayIndex, setDisplayIndex] = useState<number>(0);
  const [sortBy, setSortBy] = useState<SortOptions>("mostRecommended");

  const dispatch = useDispatch();

  const currentEpisodeFilterValue = useSelector(
    (state: RootState) => state.queries.episodeFilter
  );

  const isLoggedIn = useSelector(
    (state: RootState) => state.session.isLoggedIn
  );

  const filteredEpisodes = () => {
    const nameMatches = episodes.filter((episode) => {
      if (episode.name === null) {
        return false;
      }

      return slugify(episode.name).includes(slugify(currentEpisodeFilterValue));
    });

    const descriptionMatches = episodes.filter((episode) => {
      if (episode.description === null) {
        return false;
      }

      return slugify(episode.description).includes(
        slugify(currentEpisodeFilterValue)
      );
    });

    return Array.from(new Set(nameMatches.concat(descriptionMatches)));
  };

  const sortedEpisodes = () => {
    // sort the episodes list by recommendations, then by release date
    return [...filteredEpisodes()].sort((a, b) => {
      const firstEpisodeReleaseDate = new Date(a.releaseDate).getTime();
      const secondEpisodeReleaseDate = new Date(b.releaseDate).getTime();
      const firstEpisodeIsRecommended = a.podcasterRecommended ? 1 : 0;
      const secondEpisodeIsRecommended = b.podcasterRecommended ? 1 : 0;

      let sortType;

      switch (sortBy) {
        case "oldest":
          sortType = firstEpisodeReleaseDate - secondEpisodeReleaseDate;
          break;
        case "newest":
          sortType = secondEpisodeReleaseDate - firstEpisodeReleaseDate;
          break;
        case "mostRecommended":
          sortType =
            b.recommendations - a.recommendations ||
            secondEpisodeReleaseDate - firstEpisodeReleaseDate;
          break;
        case "podcasterRecommended":
          sortType =
            secondEpisodeIsRecommended - firstEpisodeIsRecommended ||
            b.recommendations - a.recommendations ||
            secondEpisodeReleaseDate - firstEpisodeReleaseDate;
          break;
      }

      return sortType;
    });
  };

  const handleFilterUpdate = (newValue: string) => {
    setDisplayIndex(0);
    dispatch(updateEpisodeFilter(newValue));
  };

  const handlePageUp = () => {
    const nextDisplayIndex =
      displayIndex + EPISODES_PER_PAGE > filteredEpisodes().length
        ? displayIndex
        : displayIndex + EPISODES_PER_PAGE;
    setDisplayIndex(nextDisplayIndex);
  };

  const handlePageDown = () => {
    const nextDisplayIndex =
      displayIndex - EPISODES_PER_PAGE < 0
        ? displayIndex
        : displayIndex - EPISODES_PER_PAGE;
    setDisplayIndex(nextDisplayIndex);
  };

  const handleFirstPage = () => {
    setDisplayIndex(0);
  };

  const handleLastPage = () => {
    setDisplayIndex(
      Math.floor(episodes.length / EPISODES_PER_PAGE) * EPISODES_PER_PAGE
    );
  };

  const renderMoreEpisodesToLoad = () => {
    if (!moreEpisodesToLoad) {
      return null;
    }

    return (
      <LoadingWrapper>
        <LoadingCircle />
        <LoadingMessage>Loading the most recent episodes...</LoadingMessage>
      </LoadingWrapper>
    );
  };

  const renderEpisodeList = () => {
    return sortedEpisodes()
      .slice(displayIndex, displayIndex + EPISODES_PER_PAGE)
      .map((episode) => {
        return (
          <Episode
            episode={episode}
            isLoggedIn={isLoggedIn}
            podcast={podcast}
            key={episode.id}
          />
        );
      });
  };

  const renderEpisodeNavigator = (isSecondary: boolean) => {
    if (episodes.length < EPISODES_PER_PAGE) {
      return null;
    }

    return (
      <EpisodeNavigator
        currentIndex={displayIndex}
        episodesPerPage={EPISODES_PER_PAGE}
        totalEpisodes={filteredEpisodes().length}
        isSecondary={isSecondary}
        handlePageUp={handlePageUp}
        handlePageDown={handlePageDown}
        handleFirstPage={handleFirstPage}
        handleLastPage={handleLastPage}
      />
    );
  };

  const handleSortChange = (newSortType: SortOptions) => {
    setSortBy(newSortType);
    setDisplayIndex(0);
  };

  const renderSortBySelector = () => {
    return (
      <SortBySelector
        handleSortChange={(newSortType) => handleSortChange(newSortType)}
        currentSort={sortBy}
      />
    );
  };

  const renderEpisodeFilter = () => {
    return (
      <EpisodeFilter
        placeholder="filter by name or description"
        currentValue={currentEpisodeFilterValue}
        handleUpdate={(newValue) => handleFilterUpdate(newValue)}
      />
    );
  };

  const renderEpisodeFilterAndSort = () => {
    return (
      <>
        {renderEpisodeFilter()}
        {renderSortBySelector()}
      </>
    );
  };

  return (
    <div>
      <h3>
        Showing {filteredEpisodes().length} of {episodes.length}{" "}
        {episodes.length === 1 ? "episode" : "episodes"}
      </h3>
      {renderEpisodeFilterAndSort()}
      {renderEpisodeNavigator(false)}
      {renderMoreEpisodesToLoad()}
      {renderEpisodeList()}
      {renderEpisodeNavigator(true)}
    </div>
  );
};

export default EpisodeList;
