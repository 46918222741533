import Episode from "./Episode";
import { Loading } from "./basic/Loading";
import { PodcastTitleCard } from "./Podcast";
import React from "react";
import ReviewForm from "./ReviewForm";
import { RootState } from "../store";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import useGetEpisodeInfoById from "../effects/useGetEpisodeInfoById";
import useGetEpisodesForPodcast from "../effects/useGetEpisodesForPodcast";
import useGetPodcastInfoById from "../effects/useGetPodcastInfoById";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

interface EpisodeProps {
  podcastId: string;
  episodeId: string;
}

const StyledLink = styled.p`
  &:hover {
    cursor: pointer;
  }

  .lnr {
    font-weight: 600;
    margin-left: 0.5rem;
  }
`;

export const EpisodePage: React.FunctionComponent<
  RouteComponentProps<EpisodeProps>
> = ({ match }) => {
  const { podcastId } = match.params;
  const { episodeId } = match.params;
  const history = useHistory();

  const openReviewForm = useSelector((state: RootState) => {
    return state.reviewForm.podcastId?.toString() === podcastId.toString();
  });

  const isLoggedIn = useSelector(
    (state: RootState) => state.session.isLoggedIn
  );

  // if the user is going directly to the episode page first, we want to preload all the episodes for this podcast.
  const {
    episodes,
    error: episodesError,
    isLoading: episodesAreLoading,
  } = useGetEpisodesForPodcast(podcastId);
  const {
    episode,
    error: episodeError,
    isLoading: episodeIsLoading,
  } = useGetEpisodeInfoById(podcastId, episodeId);
  const {
    podcast,
    error: podcastError,
    isLoading: podcastIsLoading,
  } = useGetPodcastInfoById(podcastId);

  const navigateToPodcast = () => {
    history.push(`/podcast/${podcastId}`);
  };

  const renderErrorMessage = () => {
    return (
      <div>
        <h3>Whoops, looks like something went wrong on our end...</h3>
        <p>
          This episode isn&apos;t working as expected. Sorry about that! Try
          searching for it again <a href="/">on the home page?</a>
        </p>
      </div>
    );
  };

  const renderTitleCard = () => {
    return podcastIsLoading ? <Loading /> : <PodcastTitleCard {...podcast} />;
  };

  const renderEpisode = () => {
    if (episodeIsLoading || podcastIsLoading || episode === undefined) {
      return <Loading />;
    }

    if (openReviewForm) {
      return <ReviewForm />;
    }

    return (
      <Episode
        episode={episode}
        isLoggedIn={isLoggedIn}
        podcast={podcast}
        expanded={true}
      />
    );
  };

  const renderPodcastLink = () => {
    return podcastIsLoading || episodeIsLoading ? (
      <Loading />
    ) : (
      <StyledLink onClick={() => navigateToPodcast()}>
        See all {podcast.totalSpotifyEpisodes} episodes for{" "}
        <strong>{podcast.name}</strong>
        <span className="lnr lnr-arrow-right"></span>
      </StyledLink>
    );
  };

  const renderMetaTags = () => {
    if (episode === undefined || podcast === undefined) {
      return null;
    }

    if (document === null) {
      return null;
    } else {
      const metaTitle = `Recs for: ${episode?.name ?? "episode"}`;
      const metaDescription = `Check out recommendations for "${podcast.name}: ${episode.name}".`;
      const metaUrl = `https://gem.fm/podcast/${podcast.id}/${episode.id}`;

      document.title = metaTitle;
      document
        .querySelector('meta[name="description"]')
        ?.setAttribute("content", metaDescription);

      document
        .querySelector('meta[property="og:title"]')
        ?.setAttribute("content", metaTitle);
      document
        .querySelector('meta[property="og:description"]')
        ?.setAttribute("content", metaDescription);
      document
        .querySelector('meta[property="og:url"]')
        ?.setAttribute("content", metaUrl);
      document
        .querySelector('meta[property="og:image"]')
        ?.setAttribute("content", podcast.image);
      document
        .querySelector('meta[property="og:type"]')
        ?.setAttribute("content", "website");
      document
        .querySelector('meta[property="og:site_name"]')
        ?.setAttribute("content", "GemFM");

      document
        .querySelector('meta[name="twitter:card"]')
        ?.setAttribute("content", "summary_large_image");
      document
        .querySelector('meta[name="twitter:creator"]')
        ?.setAttribute("content", "@maxverse");
      document
        .querySelector('meta[name="twitter:title"]')
        ?.setAttribute("content", metaTitle);
      document
        .querySelector('meta[name="twitter:description"]')
        ?.setAttribute("content", metaDescription);
      document
        .querySelector('meta[property="twitter:image"]')
        ?.setAttribute("content", podcast.image);
    }
  };

  if (podcastError || episodeError) {
    return renderErrorMessage();
  }

  renderMetaTags();

  return (
    <>
      {renderTitleCard()}
      {renderEpisode()}
      {renderPodcastLink()}
    </>
  );
};
