import { AccountAction } from "../types";
import { useState } from "react";

interface useSignupInterface {
  isLoading: boolean;
  accountActionSuccess: boolean;
  accountActionError: string | null;
  signupOrSignin: (email: string) => void;
}

export default function useAccount(action: AccountAction): useSignupInterface {
  const [accountActionSuccess, setAccountActionSuccess] =
    useState<boolean>(false);
  const [accountActionError, setAccountActionError] = useState<null | string>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const signupOrSignin = (email: string) => {
    if (email === undefined) {
      setAccountActionError(null);
      return null;
    }

    const trimmedEmail = email.trim();
    setAccountActionSuccess(false);
    setAccountActionError(null);
    setIsLoading(true);

    fetch(`/${action}`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({ email: trimmedEmail }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.success) {
          setAccountActionError(res.message);
          setIsLoading(false);
        } else {
          setAccountActionSuccess(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("Error fetching: ", error);
        setAccountActionError(error);
        setIsLoading(false);
      });
  };

  return {
    accountActionSuccess,
    accountActionError,
    isLoading,
    signupOrSignin,
  };
}
