import { ID, TagInterface } from "../../types";
import {
  clearEpisodeCommentsAndTags,
  updateEpisodeRecommendationCount,
} from "../../features/episodesSlice";
import { updateReviewForm } from "../../features/reviewFormSlice";
import { useDispatch } from "react-redux";
import { useState } from "react";

interface useRecommendEpisodeEffectInterface {
  error: string | null;
  isLoading: boolean;
  recommendEpisode: () => void;
}

export default function useRecommendEpisode(
  podcastId: ID,
  episodeId: ID
): useRecommendEpisodeEffectInterface {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const submitOnlyRecommendationCountChange = () => {
    setIsLoading(true);
    fetch(`/api/v1/recommend`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({ episode_id: episodeId }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.change === -1) {
          dispatch(clearEpisodeCommentsAndTags({ podcastId, episodeId }));
        }

        dispatch(
          updateEpisodeRecommendationCount({
            podcastId,
            episodeId,
            change: res.change,
          })
        );
        dispatch(
          updateReviewForm({
            comment: res.newRecommendation?.comment,
            tags: res.newRecommendation?.tags?.map(
              (tag: TagInterface) => tag.id
            ),
          })
        );
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
        console.log("Error fetching: ", error);
      });
  };

  return {
    error,
    isLoading,
    recommendEpisode: submitOnlyRecommendationCountChange,
  };
}
