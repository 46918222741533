import { createGlobalStyle } from "styled-components";

const GlobalStylesheet = createGlobalStyle`
  * {
    font-family: "Arial", sans-serif;
    font-family: 'Mulish', sans-serif;
    font-weight: 300;
  }

  a, a:visited, a:active {
    color: ${({ theme }) => theme.colors.blue};
  }

  strong {
    font-weight: 800;
  }

  body {
    margin: 0;
    padding: 0;
  }

  .App {
    position: relative;
    min-height: 100vh;
    min-width: 300px;
    box-sizing: border-box;
    
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};

    @media only screen and (max-width: 768px) {
      margin: 0;
    }
  }

  input,
  textarea {
    font-size: 1rem;
    border-radius: 0.3rem;
    background: ${({ theme }) => theme.colors.whiteBright};
    padding: 0.5rem;
    resize: none;
    width: 100%;
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.black};
    border: ${({ theme }) => theme.borders.lightGray};

    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${({ theme }) => theme.colors.olive};
    }
    :-ms-input-placeholder {
       color: ${({ theme }) => theme.colors.olive};
    }

    &:focus {
      outline: none;
    }
  }

  h1, h2, h3, h4, h5, h6, h7 {
    font-family: 'PT Serif', serif;
  }

  .large-icon {
    font-size: 2.5rem;
    padding-bottom: 2rem;
    display: block;

    &:hover {
      cursor: pointer;
    }

    @media only screen and (max-width: 768px) {
      padding-bottom: 1rem;
    }
  }

`;

export default GlobalStylesheet;
