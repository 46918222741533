import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { PodcastInterface } from "../types";
import { RootState } from "../store";
import { addPodcast } from "../features/podcastsSlice";

interface Error {
  message: string | null;
  code?: number | null;
}
interface podcastEffectInterface {
  podcast: PodcastInterface;
  error: Error | null;
  isLoading: boolean;
}

export default function useGetPodcastInfoById(
  podcastId: string | number
): podcastEffectInterface {
  const dispatch = useDispatch();
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // TODO: standardize how we check loading & data from redux on each effect/slice

  const matchingPodcastForThisId = useSelector((state: RootState) => {
    return state.podcasts[Number(podcastId)];
  });

  useEffect(() => {
    if (matchingPodcastForThisId === undefined) {
      setIsLoading(true);
      fetch(`/api/v1/podcast_info/${podcastId}`)
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            setError({
              message: res.error,
              code: res.status,
            });
          } else {
            dispatch(addPodcast(res));
          }

          setIsLoading(false);
        })
        .catch((error) => {
          setError({ message: error });
          console.log("Error fetching: ", error);
        });
    } else {
      setIsLoading(false);
    }
  }, [matchingPodcastForThisId]);

  return { isLoading, error, podcast: matchingPodcastForThisId };
}
