import React from "react";
import styled from "styled-components";

const StyledFooter = styled.div`
  background: ${({ theme }) => theme.colors.whiteBright};
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.grayLightest};
  padding: 1rem 0;
  width: 100vw;

  .lnr {
    padding: 0 0.5rem;
    font-size: 1.2rem;
  }

  .lnr-coffee-cup {
    font-size: 1rem;
  }

  a {
    font-family: "PT Serif", serif;
    padding-left: 0.3rem;
    text-decoration: none;
  }
`;

const Footer: React.FunctionComponent = () => {
  return (
    <StyledFooter>
      Made with <span className="lnr lnr-coffee-cup"></span> and{" "}
      <span className="lnr lnr-volume-medium"></span> by{" "}
      <a
        href="https://maximpekarsky.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        {" "}
        Max Pekarsky
      </a>
    </StyledFooter>
  );
};

export default Footer;
