import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { PodcastInterface } from "../types";
import { RootState } from "../store";
import { addPodcasts } from "../features/podcastsSlice";
import { addSearch } from "../features/searchSlice";
import { updatePodcastSearchTimestamp } from "../features/querySlice";

interface podcastEffectInterface {
  podcasts: PodcastInterface[] | [];
  error: string | null;
  isLoading: boolean;
}

const DEBOUNCE_TIME = 750;

export default function useSearchPodcastByName(
  podcastQuery: string
): podcastEffectInterface {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [timer, setTimer] = useState(setTimeout(() => null, 0));

  const allPodcasts = useSelector((state: RootState) => state.podcasts);

  const idsForThisSearch: number[] | [] = useSelector((state: RootState) => {
    return state.searches[podcastQuery] ?? [];
  });

  const matchingPodcastsForThisSearch: PodcastInterface[] | [] =
    idsForThisSearch.map((id) => {
      return allPodcasts[id];
    });

  // TODO: standardize how we check loading & data from redux on each effect/slice

  const lastSearchTimestamp = useSelector(
    (state: RootState) => state.queries.lastSearchTimestamp
  );
  const timestamp = Date.now();

  const enoughTimeHasPassed = () => {
    const timeSinceLastSearch = timestamp - lastSearchTimestamp;

    return timeSinceLastSearch > DEBOUNCE_TIME;
  };

  const executeSearch = () => {
    const startTime = Date.now();
    fetch(`/api/v1/podcast_search/${podcastQuery}`)
      .then((res) => res.json())
      .then((res) => {
        if (res.podcasts !== undefined) {
          dispatch(addPodcasts(res.podcasts));
          dispatch(
            addSearch({
              query: podcastQuery,
              podcastIds: res.podcasts.map(
                (podcast: PodcastInterface) => podcast.id
              ),
            })
          );
        }
        console.log(Date.now() - startTime, "podcast fetch time");
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
        console.log("Error fetching: ", error);
      });
  };

  useEffect(() => {
    if (
      !matchingPodcastsForThisSearch.length &&
      podcastQuery?.trim().length > 2
    ) {
      dispatch(updatePodcastSearchTimestamp(timestamp));
      setIsLoading(true);

      if (enoughTimeHasPassed()) {
        executeSearch();
      } else {
        // this is a little funky, but we need to save this setTimeout in a state variable
        // to prevent it from resetting on component re-render
        clearTimeout(timer);
        setTimer(setTimeout(executeSearch, DEBOUNCE_TIME));
      }
    } else {
      setIsLoading(false);
    }
  }, [podcastQuery]);

  return { isLoading, error, podcasts: matchingPodcastsForThisSearch };
}
