import styled from "styled-components";

export const Description = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  word-break: break-word;
  line-height: 1.4;
  flex-grow: 1;
`;

export const ExpandedDescription = styled(Description)`
  display: block;
`;
