import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { RootState } from "../store";
import styled from "styled-components";
import { updatePodcastQuery } from "../features/querySlice";

const StyledSearchBar = styled.div`
  display: flex;
  background: #efefef;
  border-radius: 0.3rem;
  border: ${({ theme }) => theme.borders.lightGray};
  margin: 2rem 0;

  .lnr-magnifier {
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.7rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.olive};
    background: ${({ theme }) => theme.colors.whiteBright};
    border-radius: 0.3rem 0 0 0.3rem;

    @media only screen and (max-width: 768px) {
      font-size: 1.3rem;
    }
  }
`;

const StyledSearchInput = styled.input`
  font-size: 2.2rem;
  border-radius: 0 0.3rem 0.3rem 0;
  border: none;

  @media only screen and (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

const SearchBar: React.FunctionComponent = () => {
  const podcastQuery = useSelector(
    (state: RootState) => state.queries.podcastQuery
  );

  const dispatch = useDispatch();

  return (
    <StyledSearchBar>
      <span className="lnr lnr-magnifier"></span>
      <StyledSearchInput
        autoFocus
        onChange={(e) => dispatch(updatePodcastQuery(e.target.value))}
        value={podcastQuery}
        type="text"
        placeholder="look up a podcast"
      />
    </StyledSearchBar>
  );
};

export default SearchBar;
