import React from "react";
import { SortOptions } from "../types";
import styled from "styled-components";

const SortOptionWrapper = styled.div`
  font-size: 0.9rem;
  margin-bottom: 1.1rem;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    line-height: 2;
  }
`;

const SortOptionRow = styled.div`
  display: flex;
  justify-content: flex-start;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const SortIcon = styled.span`
  font-size: 1.3rem;
  padding-right: 1rem;
`;

const SortOption = styled.div<{ selected: boolean }>`
  box-sizing: content-box;
  padding-right: 1rem;
  font-weight: ${(props) => (props.selected ? "700" : "300")};
  user-select: none;

  .lnr {
    font-weight: ${(props) => (props.selected ? "700" : "300")};
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

type SortByProps = {
  handleSortChange: (type: SortOptions) => void;
  currentSort: SortOptions;
};

export const SortBySelector: React.FunctionComponent<SortByProps> = ({
  handleSortChange,
  currentSort,
}) => {
  const isSelected = (type: string) => {
    return type === currentSort;
  };

  return (
    <SortOptionWrapper>
      <SortIcon className="lnr lnr-sort-amount-asc"></SortIcon>
      <SortOptionRow>
        <SortOption
          selected={isSelected("mostRecommended")}
          onClick={() => handleSortChange("mostRecommended")}
        >
          <span className="lnr lnr-star"></span> Most Recommended
        </SortOption>
        <SortOption
          selected={isSelected("podcasterRecommended")}
          onClick={() => handleSortChange("podcasterRecommended")}
        >
          Recommended by Podcaster
        </SortOption>
      </SortOptionRow>
      <SortOptionRow>
        <SortOption
          selected={isSelected("newest")}
          onClick={() => handleSortChange("newest")}
        >
          Newest
        </SortOption>
        <SortOption
          selected={isSelected("oldest")}
          onClick={() => handleSortChange("oldest")}
        >
          Oldest
        </SortOption>
      </SortOptionRow>
    </SortOptionWrapper>
  );
};
