import { Card } from "./basic/Card";
import { Podcast } from "./Podcast";
import { PodcastInterface } from "../types";
import React from "react";

type PodcastListProps = {
  podcasts: PodcastInterface[] | [];
};

const PodcastList: React.FunctionComponent<PodcastListProps> = ({
  podcasts,
}) => {
  const renderPodcastList = () => {
    return podcasts.map((podcast) => <Podcast {...podcast} key={podcast.id} />);
  };

  const renderResultsHeading = () => {
    if (!podcasts.length) {
      return (
        <Card>
          <h3>No podcasts found with this name. Try another?</h3>
        </Card>
      );
    }

    return (
      <h3>
        Found {podcasts.length} {podcasts.length > 1 ? "podcasts" : "podcast"}:
      </h3>
    );
  };

  return (
    <div>
      {renderResultsHeading()}
      {renderPodcastList()}
    </div>
  );
};

export default PodcastList;
