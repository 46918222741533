import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SessionInterface } from "../types";

const initialState: SessionInterface = {
  clientDomain: "#",
  serverDomain: "#",
  isLoggedIn: false,
  user: {
    email: null,
    admin: false,
  },
  fetched: false,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    saveSession: (state, action: PayloadAction<SessionInterface>) => {
      // we can either set one state property to payload, or return a whole new object.
      return { ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveSession } = sessionSlice.actions;

export default sessionSlice.reducer;
