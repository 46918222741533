import { AccountAction, UserInterface } from "../types";
import React, { useEffect, useState } from "react";
import { Card } from "./basic/Card";
import { ConfirmationModal } from "./ConfirmationModal";
import { LoadingCircle } from "./basic/Loading";
import { SubmitButton } from "./basic/Button";
import styled from "styled-components";
import useAccount from "../effects/useAccount";
import { useHistory } from "react-router-dom";

const AccountFormWrapper = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    padding-bottom: 0;
  }

  label {
    font-size: 0.9rem;
    color: ${({ theme }) => theme.colors.black};
    font-weight: 600;
    padding-bottom: 0.5rem;
  }
`;

const Link = styled.span`
  color: ${({ theme }) => theme.colors.blue};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

const EmailInputWrapper = styled.div`
  margin-top: 1rem;
`;

const PageHeader = styled.h2`
  display: flex;
  padding-bottom: 0.5rem;
  align-items: center;
`;

const Separator = styled.span`
  padding: 0 1rem;
`;

const HeaderAction = styled.span<{ selected: boolean }>`
  font-family: "PT Serif", serif;
  margin: 0;
  color: ${(props) =>
    props.selected
      ? ({ theme }) => theme.colors.black
      : ({ theme }) => theme.colors.grayLight};

  &:hover {
    cursor: pointer;
  }
`;

const PageDescription = styled.p`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 1rem;
  margin: 0;
  padding: 0.5rem 0;
`;

const Error = styled.p`
  margin: 0;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.redLight};
  padding: 0.5rem 0;
`;

type AccountProps = {
  user: UserInterface | null;
  serverDomain: string;
  action: AccountAction;
};

export const Account: React.FunctionComponent<AccountProps> = ({
  user,
  serverDomain,
  action: initialAction,
}) => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [action, setAction] = useState<AccountAction>(initialAction);
  const [displayConfirmation, setDisplayConfirmation] =
    useState<boolean>(false);

  const {
    accountActionSuccess,
    accountActionError,
    isLoading: accountIsLoading,
    signupOrSignin,
  } = useAccount(action);
  const history = useHistory();

  const actionLabel = action === "login" ? "Log in" : "Sign up";
  const actionDescription =
    action === "login"
      ? "Log in to add new reviews and edit your past reviews!"
      : "Sign up to add reviews and receive personalized episode recommendations!";

  useEffect(() => {
    clearError();
  }, [email]);

  useEffect(() => {
    if (accountActionSuccess) {
      setDisplayConfirmation(true);
    }

    if (accountActionError) {
      setError(accountActionError);
    }
  }, [accountActionSuccess, accountActionError]);

  const clearError = () => setError(null);

  const handleInput = (newValue: string) => {
    setEmail(newValue);
    clearError();
  };

  const navigateToHome = () => {
    history.push(`/`);
  };

  const emailIsValid = () => {
    return (
      email.trim().length > 0 && email.includes("@") && email.includes(".")
    );
  };

  const handleSubmit = () => {
    if (emailIsValid()) {
      signupOrSignin(email);
    } else {
      setError("That email doesn't look right");
    }
  };

  const renderRoutingError = () => {
    return (
      <AccountFormWrapper>
        <PageHeader>Hmm...</PageHeader>
        <PageDescription>
          Something doesn&apos;t look right here.
        </PageDescription>
      </AccountFormWrapper>
    );
  };

  const handleClose = () => {
    setDisplayConfirmation(false);
    navigateToHome();
  };

  const switchAction = () => {
    const newAction = action === "login" ? "signup" : "login";
    history.push(`/${newAction}`);
    setAction(newAction);
  };

  const renderConfirmation = () => {
    return (
      <ConfirmationModal
        handleClose={() => handleClose()}
        title="Success!"
        subtitle="Check your email for a sign in link."
        closeMessage="Close"
      />
    );
  };

  const renderSignedInSection = () => {
    return (
      <Card>
        <AccountFormWrapper>
          <PageHeader>Account</PageHeader>
          <p>
            Signed in as <strong>{user?.email ?? "user"}</strong>
          </p>
          <br />
          <a href={`${serverDomain}/signout`}>
            Sign out <span className="lnr lnr-arrow-right"></span>
          </a>
        </AccountFormWrapper>
      </Card>
    );
  };

  const renderAlternativeAction = () => {
    const questionLabel =
      action === "signup"
        ? "Already have an account? "
        : "Don't have an account yet? ";
    const linkLabel =
      action === "signup" ? "Log in instead" : "Sign up instead";
    return (
      <>
        {questionLabel}
        <Link onClick={() => switchAction()}>
          {linkLabel} <span className="lnr lnr-arrow-right"></span>
        </Link>
      </>
    );
  };

  const renderPageHeader = () => {
    const signUpSelected = action === "signup";
    const logInSelected = action === "login";

    return (
      <PageHeader>
        <HeaderAction selected={signUpSelected} onClick={() => switchAction()}>
          Sign up
        </HeaderAction>
        <Separator>|</Separator>
        <HeaderAction selected={logInSelected} onClick={() => switchAction()}>
          Log in
        </HeaderAction>
      </PageHeader>
    );
  };

  const renderSubmitButton = () => (
    <SubmitButton onClick={() => handleSubmit()}>{actionLabel}</SubmitButton>
  );

  const renderEmailForm = () => {
    if (displayConfirmation) {
      return renderConfirmation();
    }

    return (
      <Card>
        <AccountFormWrapper>
          {renderPageHeader()}
          <PageDescription>{actionDescription}</PageDescription>

          <EmailInputWrapper>
            <Error>{error}</Error>
            <input
              type="email"
              placeholder="Email"
              onChange={(e) => handleInput(e.target.value)}
              value={email}
            />
          </EmailInputWrapper>

          {accountIsLoading ? <LoadingCircle /> : renderSubmitButton()}

          <PageDescription>{renderAlternativeAction()}</PageDescription>
        </AccountFormWrapper>
      </Card>
    );
  };

  if (action !== "login" && action !== "signup") {
    return renderRoutingError();
  }
  return user === null ? renderEmailForm() : renderSignedInSection();
};
