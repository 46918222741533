import React from "react";
import { RootState } from "../store";
import styled from "styled-components";

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
`;

const SearchIcon = styled.span`
  font-size: 1.3rem;
  padding-right: 1rem;
`;

type FilterProps = {
  currentValue: string;
  handleUpdate: (newValue: string) => void;
  placeholder: string;
};

export const EpisodeFilter: React.FunctionComponent<FilterProps> = ({
  currentValue,
  handleUpdate,
  placeholder,
}) => {
  return (
    <FilterWrapper>
      <SearchIcon className="lnr lnr-magnifier"></SearchIcon>
      <input
        placeholder={placeholder}
        onChange={(e) => handleUpdate(e.target.value)}
        value={currentValue}
      />
    </FilterWrapper>
  );
};
