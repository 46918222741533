import React, { useState } from "react";
import { ReviewFormInterface, TagInterface } from "../types";
import { clearReviewForm, updateReviewForm } from "../features/reviewFormSlice";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmationModal } from "./ConfirmationModal";
import { Container } from "../components/basic/Container";
import { LoadingCircle } from "./basic/Loading";
import { RootState } from "../store";
import { SubmitButton } from "./basic/Button";
import { Tag } from "./basic/Tag";
import styled from "styled-components";
import useDeleteRecommendation from "../effects/mutations/useDeleteRecommendation";
import useSubmitReviewForm from "../effects/mutations/useSubmitReviewForm";
import useTags from "../effects/useTags";

const ERROR_TEXT = {
  BLANK_COMMENT: "Comment can't be blank",
  BLANK_EMAIL: "Email can't be blank",
  INCORRECT_EMAIL: "Hm, that email doesn't look right...",
};

const ReviewFormWrapper = styled.div`
  border-radius: 0.3rem;
  border: 1px solid #e4e4e4;
  background: ${({ theme }) => theme.colors.whiteBright};
`;

const FormActionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 250px;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin: 1rem auto;

  button {
    font-size: 1rem;
  }
`;

const StyledContainer = styled(Container)`
  padding-top: 0;
  display: block;
  padding: 0;

  label {
    font-size: 0.9rem;
    font-weight: 600;
    padding-bottom: 0.5rem;
  }
`;

const QuestionWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
`;

const EmailFormWrapper = styled(Container)`
  background: ${({ theme }) => theme.colors.greenLight};

  label {
    color: ${({ theme }) => theme.colors.green};
  }
`;

const CommentField = styled.textarea`
  border: ${({ theme }) => theme.borders.lightGray};
`;

const EmailField = styled.input`
  border: ${({ theme }) => theme.borders.lightGray};
`;

const ConfirmMessage = styled.h3`
  margin: 0;
  font-weight: 300;
  line-height: 1.4;
  padding: 0.5rem 0;

  .lnr {
    margin-right: 0.5rem;
    font-weight: 600;

    &.lnr-checkmark-circle,
    .lnr-envelope {
      color: ${({ theme }) => theme.colors.greenHappy};
    }
  }

  .lnr-envelope {
    font-size: 2rem;
    font-weight: initial;
  }

  @media only screen and (max-width: 768px) {
    font-size: 1rem;

    .lnr-checkmark-circle {
      margin-right: 0.5rem;
    }
  }
`;

const Title = styled.p`
  margin: 0;
  font-size: 1.6rem;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "PT Serif";

  @media only screen and (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const Subtitle = styled(Title)`
  display: block;
  font-size: 1rem;
  text-align: center;
  color: #585858;
  font-family: "Mulish", sans-serif;
  padding: 0 0.5rem 0.5rem 0.5rem;

  @media only screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const CloseButton = styled.div`
  text-align: right;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;

  &:hover {
    cursor: pointer;
  }
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.redLight};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const EmailExplanation = styled.p`
  font-size: 0.9rem;
  line-height: 1.5;
  margin: 0;
  padding-bottom: 1rem;
`;

const EmailExplanationWrapper = styled.div``;

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Error = styled.div`
  color: #e25b5b;
  padding-bottom: 0.5rem;
  font-weight: 600;
  font-size: 0.8rem;
`;

const ReviewForm: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const reviewFormState = useSelector((state: RootState) => state.reviewForm);
  const isLoggedIn = useSelector(
    (state: RootState) => state.session.isLoggedIn
  );
  const comment = reviewFormState.comment ?? "";
  const {
    podcastId,
    episodeId,
    editing = false,
    tags: selectedTags = [],
    email = "",
  } = reviewFormState;

  if (podcastId === undefined || episodeId === undefined) {
    return null;
  }

  const { submitReviewForm } = useSubmitReviewForm();
  const { deleteRecommendation } = useDeleteRecommendation({
    podcastId,
    episodeId,
  });
  const { tags, isLoading: tagsAreLoading, error: tagsError } = useTags();

  const [errors, setErrors] = useState<string[]>([]);
  const [showEmailReminder, setShowEmailReminder] = useState<boolean>(false);

  const episode = useSelector((state: RootState) => {
    return state.episodes[Number(podcastId)].filter(
      (e) => e.id === episodeId
    )[0];
  });

  const handleFormUpdate = (updatedField: {
    [K in keyof ReviewFormInterface]: ReviewFormInterface[K];
  }) => {
    dispatch(updateReviewForm(updatedField));
  };

  const handleClose = () => {
    dispatch(clearReviewForm());
  };

  const handleDeleteRecommendation = () => {
    if (
      window.confirm("Are you sure you want to remove this recommendation?")
    ) {
      deleteRecommendation();
      dispatch(clearReviewForm());
    } else {
      return;
    }
  };

  const handleSubmit = () => {
    if (inputIsValid()) {
      submitValidReviewForm();
    }
  };

  const submitValidReviewForm = () => {
    clearErrors();
    submitReviewForm();

    if (isLoggedIn) {
      dispatch(clearReviewForm());
    } else {
      setShowEmailReminder(true);
    }
  };

  const inputIsValid = () => {
    let isValid = true;

    if (!comment.trim().length) {
      isValid = false;
      addError(ERROR_TEXT.BLANK_COMMENT);
    }

    if (!isLoggedIn) {
      if (!email.trim().length) {
        isValid = false;
        addError(ERROR_TEXT.BLANK_EMAIL);
      }

      if (email && email.length) {
        if (!email.includes("@") || !email.includes(".")) {
          isValid = false;
          addError(ERROR_TEXT.INCORRECT_EMAIL);
        }
      }
    }

    return isValid;
  };

  const addError = (newError: string) => {
    if (errors.includes(newError)) {
      return;
    }
    setErrors([...errors, newError]);
  };

  const renderErrors = () => {
    if (!errors.length) {
      return null;
    }
    return (
      <Container>
        {errors.map((error) => (
          <Error key={error}>{error}</Error>
        ))}
      </Container>
    );
  };

  const clearErrors = () => {
    setErrors([]);
  };

  const isSelected = (id: string | number) => {
    if (selectedTags === undefined) {
      return false;
    }

    return tags !== undefined ? selectedTags.includes(id) : false;
  };

  const toggleTag = (id: string | number) => {
    if (selectedTags === undefined) {
      return false;
    }

    if (selectedTags.includes(id)) {
      handleFormUpdate({ tags: selectedTags.filter((tagId) => tagId !== id) });
    } else {
      handleFormUpdate({ tags: [...selectedTags, id] });
    }
  };

  const renderTags = () => {
    if (tagsError) {
      console.log(tagsError);
    }

    return tagsAreLoading ? (
      <LoadingCircle />
    ) : (
      <TagWrapper>{tags.map((tag) => renderTag(tag))}</TagWrapper>
    );
  };

  const renderTagSection = () => {
    if (tags === undefined) {
      return null;
    }

    return (
      <QuestionWrapper>
        <label>Tags</label>
        {renderTags()}
      </QuestionWrapper>
    );
  };

  const renderTag = (tag: TagInterface) => {
    return (
      <Tag
        key={tag.id}
        onClick={() => toggleTag(tag.id)}
        selected={isSelected(tag.id)}
        color={tag.colorCode}
      >
        {tag.name}
      </Tag>
    );
  };

  const renderEmailForm = () => {
    if (isLoggedIn) {
      return null;
    }

    return (
      <EmailFormWrapper>
        <label>Email</label>
        <EmailExplanationWrapper>
          <EmailExplanation>
            You need an account so you can manage/edit your reviews and get
            personalized episode recs! Create one or sign in with your email
            below.
          </EmailExplanation>
          <EmailExplanation>
            <strong>You&apos;ll need to confirm your email</strong> in order to
            publish your recommendation.
          </EmailExplanation>
        </EmailExplanationWrapper>
        <EmailField
          type="email"
          placeholder="Email"
          onChange={(e) => handleFormUpdate({ email: e.target.value })}
          value={email}
        />
      </EmailFormWrapper>
    );
  };

  const renderReminderToConfirmEmail = () => {
    return (
      <ConfirmationModal
        handleClose={() => handleClose()}
        title="Comment submitted"
        subtitle="Please click the link we sent to your email to log in and publish this comment!"
        closeMessage="Back to podcast"
      />
    );
  };

  const renderFormTitle = () => {
    return editing ? (
      <Title>
        <span className="lnr lnr-pencil"></span>
        Edit review
      </Title>
    ) : (
      <Title>
        <span className="lnr lnr-checkmark-circle"></span>
        Thanks for recommending!
      </Title>
    );
  };

  const renderSubmitButton = () => {
    const label = editing ? "Update recommendation" : "Submit recommendation";

    return <SubmitButton onClick={() => handleSubmit()}>{label}</SubmitButton>;
  };

  const renderDeleteButton = () => {
    if (!editing) {
      return null;
    }

    return (
      <DeleteButton onClick={() => handleDeleteRecommendation()}>
        Delete Recommendation
      </DeleteButton>
    );
  };

  if (showEmailReminder) {
    return renderReminderToConfirmEmail();
  }

  return (
    <ReviewFormWrapper>
      <CloseButton onClick={() => handleClose()}>
        <span className="lnr lnr-cross"></span>
      </CloseButton>
      <StyledContainer>
        <ConfirmMessage>
          {renderFormTitle()}
          <Subtitle>
            Help others learn more about <strong>{episode.name}</strong>
          </Subtitle>
        </ConfirmMessage>

        {renderErrors()}

        <QuestionWrapper>
          <label>Comment</label>
          <CommentField
            rows={4}
            placeholder="Why do you like this episode? No spoilers!"
            onChange={(e) => handleFormUpdate({ comment: e.target.value })}
            value={comment}
          />
        </QuestionWrapper>

        {renderTagSection()}
        {renderEmailForm()}

        <FormActionWrapper>
          {renderSubmitButton()}
          {renderDeleteButton()}
        </FormActionWrapper>
      </StyledContainer>
    </ReviewFormWrapper>
  );
};

export default ReviewForm;
