import { ID, ReviewFormInterface } from "../types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: ReviewFormInterface = {
  comment: "",
  tags: [],
  email: "",
  editing: false,
};

export const reviewFormSlice = createSlice({
  name: "reviewForm",
  initialState,
  reducers: {
    openReviewForm: (
      state,
      action: PayloadAction<{
        podcastId: ID;
        episodeId: ID;
        editing?: boolean;
      }>
    ) => {
      return { ...state, ...action.payload };
    },
    updateReviewForm: (
      state,
      action: PayloadAction<{
        [K in keyof ReviewFormInterface]: ReviewFormInterface[K];
      }>
    ) => {
      return { ...state, ...action.payload };
    },
    clearReviewForm: () => {
      return initialState;
    },
  },
});

export const { openReviewForm, updateReviewForm, clearReviewForm } =
  reviewFormSlice.actions;

export default reviewFormSlice.reducer;
