import { ID, TrendingEpisodeInterface } from "../types";
import { Loading } from "./basic/Loading";
import { Name } from "./basic/Name";
import React from "react";
import { clearEpisodeFilter } from "../features/querySlice";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useTrendingEpisodes from "../effects/useTrendingEpisodes";

const SectionWrapper = styled.div`
  padding-top: 2rem;
`;

const TrendingEpisodesHeading = styled.h3`
  font-size: 1.6rem;
  margin: 0;
  padding-bottom: 1rem;
`;

const EpisodeListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const TrendingEpisode = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(calc(100% / 3) - 2rem);
  margin-bottom: 2rem;
  border-radius: 0.3rem;
  background: ${({ theme }) => theme.colors.whiteBright};
  box-shadow: ${({ theme }) => theme.boxShadow.standard};

  @media only screen and (max-width: 768px) {
    width: calc(50% - 0.6rem);
    margin-bottom: 1rem;
  }
`;

const EpisodeImage = styled.img`
  width: 100%;
  height: auto;
  background: #efefef;
  border-radius: 0.3rem 0.3rem 0 0;

  &:hover {
    cursor: pointer;
  }
`;

const PodcastName = styled(Name)`
  text-transform: uppercase;
  font-weight: 800;
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.olive};
  padding: 0 0 0.5rem 0;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const EpisodeName = styled.div`
  font-size: 0.9rem;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Metadata = styled.div`
  padding: 0.5rem 0.3rem;
`;

const TrendingEpisodes: React.FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { trendingEpisodes, error, isLoading } = useTrendingEpisodes();

  const navigateToPodcast = (podcastId: ID) => {
    dispatch(clearEpisodeFilter());
    history.push(`/podcast/${podcastId}`);
  };

  const navigateToEpisode = (podcastId: ID, episodeId: ID) => {
    history.push(`/podcast/${podcastId}/${episodeId}`);
  };

  const renderTrendingEpisodes = () => {
    if (isLoading) return <Loading />;
    if (error) return <div>Error!</div>;

    if (!trendingEpisodes.length) {
      return (
        <p>
          Looks like there are no trending episodes yet. Try searching for some!
        </p>
      );
    }

    return trendingEpisodes.map((episode: TrendingEpisodeInterface) => {
      return (
        <TrendingEpisode key={episode.id}>
          <EpisodeImage
            src={episode.image}
            onClick={() => navigateToEpisode(episode.podcastId, episode.id)}
          />
          <Metadata>
            <PodcastName onClick={() => navigateToPodcast(episode.podcastId)}>
              {episode.podcastName}{" "}
            </PodcastName>
            <EpisodeName
              onClick={() =>
                history.push(`/podcast/${episode.podcastId}/${episode.id}`)
              }
            >
              {episode.episodeName}
            </EpisodeName>
          </Metadata>
        </TrendingEpisode>
      );
    });
  };

  return (
    <SectionWrapper>
      <TrendingEpisodesHeading>Trending episodes</TrendingEpisodesHeading>
      <EpisodeListWrapper>{renderTrendingEpisodes()}</EpisodeListWrapper>
    </SectionWrapper>
  );
};

export default TrendingEpisodes;
