import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { EpisodeInterface } from "../types";
import { RootState } from "../store";
import { addEpisodes } from "../features/episodesSlice";

interface podcastEffectInterface {
  episodes: EpisodeInterface[] | [];
  error: string | null;
  isLoading: boolean;
  moreEpisodesToLoad: boolean;
}

export default function useGetEpisodesForPodcast(
  podcastId: string | number
): podcastEffectInterface {
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [moreEpisodesToLoad, setMoreEpisodesToLoad] = useState(false);

  let startTime = 0;

  // TODO: standardize how we check loading & data from redux on each effect/slice

  const matchingEpisodesForThisPodcasts = useSelector((state: RootState) => {
    return state.episodes[Number(podcastId)];
  });

  const getEpisodesFromServer = (offset = 0) => {
    startTime = Date.now();
    fetch(`/api/v1/podcast_episodes/${podcastId}?offset=${offset}`)
      .then((res) => res.json())
      .then((res) => {
        if (!res.episodes) {
          setError("couldn't find episodes for this podcast" as const);
        } else {
          dispatch(addEpisodes({ episodes: res.episodes, podcastId }));
          console.log(Date.now() - startTime, "fetch time");

          setMoreEpisodesToLoad(false);

          if (res.needMoreEpisodes) {
            setMoreEpisodesToLoad(true);
            getEpisodesFromServer(res.nextOffset);
          } else {
            setMoreEpisodesToLoad(false);
          }
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        console.log("Error fetching: ", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (matchingEpisodesForThisPodcasts === undefined) {
      getEpisodesFromServer();
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [matchingEpisodesForThisPodcasts]);

  return {
    isLoading,
    error,
    episodes: matchingEpisodesForThisPodcasts,
    moreEpisodesToLoad,
  };
}
