import { Card } from "./basic/Card";
import { Container } from "../components/basic/Container";
import React from "react";
import { SubmitButton } from "./basic/Button";
import styled from "styled-components";

const CloseButton = styled.div`
  text-align: right;
  padding: 0 0.5rem 1rem 0.5rem;
  font-size: 1.5rem;

  &:hover {
    cursor: pointer;
  }
`;

const StyledContainer = styled(Container)`
  padding-top: 0;
  display: block;
`;

const ConfirmMessage = styled.h3`
  margin: 0;
  font-weight: 300;
  line-height: 1.4;
  padding: 0.5rem 0;

  .lnr-checkmark-circle,
  .lnr-envelope {
    color: #42bf42;
    margin-right: 0.5rem;
    font-weight: 600;
  }

  .lnr-envelope {
    font-size: 2rem;
    font-weight: initial;
  }

  @media only screen and (max-width: 768px) {
    font-size: 1rem;

    .lnr-checkmark-circle {
      margin-right: 0.5rem;
    }
  }
`;

const Title = styled.p`
  margin: 0;
  font-size: 1.6rem;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Subtitle = styled(Title)`
  display: block;
  font-size: 1rem;
  text-align: center;
  color: #585858;

  @media only screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

type ConfirmationModalProps = {
  handleClose: () => void;
  title: string;
  subtitle: string;
  closeMessage: string;
};

export const ConfirmationModal: React.FunctionComponent<
  ConfirmationModalProps
> = ({ handleClose, title, closeMessage, subtitle }) => {
  return (
    <Card>
      <CloseButton onClick={() => handleClose()}>
        <span className="lnr lnr-cross"></span>
      </CloseButton>
      <StyledContainer>
        <ConfirmMessage>
          <Title>
            <span className="lnr lnr-envelope"></span>
            {title}
          </Title>
          <Subtitle>{subtitle}</Subtitle>
        </ConfirmMessage>
        <SubmitButton onClick={() => handleClose()}>
          {closeMessage}
        </SubmitButton>
      </StyledContainer>
    </Card>
  );
};
