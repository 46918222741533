import { useEffect, useState } from "react";

interface AdminDataInterface {
  data: any; // TODO: aaaaah! no!
  error: string | null;
  isLoading: boolean;
}

export default function useAdminData(): AdminDataInterface {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  // // TODO: standardize how we check loading & data from redux on each effect/slice

  useEffect(() => {
    fetch(`/api/v1/admin`)
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false);

        if (res.error) {
          window.location.href = "/";
        }

        setData(res);
      })
      .catch((error) => {
        setError(error);
        console.log("Error fetching: ", error);
      });

    setIsLoading(false);
  }, [isLoading]);

  return {
    isLoading,
    error,
    data,
  };
}
