import styled from "styled-components";

export const Container = styled.div`
  padding: 1rem;
  max-width: 768px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;

  @media only screen and (max-width: 768px) {
    max-width: 480px;
  }
`;

export const FullPageContainer = styled(Container)`
  min-height: 100vh;
  box-sizing: border-box;
`;
