import { ErrorCard } from "./basic/Card";
import FullCenteredPage from "./basic/FullCenteredPage";
import React from "react";
import { SubmitButton } from "./basic/Button";
import { useHistory } from "react-router-dom";

const PageNotFound: React.FunctionComponent = () => {
  const history = useHistory();

  const navigateTo = (url: string) => {
    history.push(url);
  };

  return (
    <FullCenteredPage>
      <ErrorCard>
        <h2>Oh no, it&apos;s a 404!</h2>
        <p>Looks like this page doesn&apos;t exist.</p>
        <br />
        <SubmitButton onClick={() => navigateTo("/")}>
          Do another take
        </SubmitButton>
      </ErrorCard>
    </FullCenteredPage>
  );
};

export default PageNotFound;
