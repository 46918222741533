import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { updateEpisodeCommentsAndTags } from "../../features/episodesSlice";
import { useState } from "react";

interface useSubmitReviewFormInterface {
  error: string | null;
  isLoading: boolean;
  submitReviewForm: () => void;
}

export default function useSubmitReviewForm(): useSubmitReviewFormInterface {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const {
    comment,
    tags,
    email,
    podcastId,
    episodeId: episode_id,
  } = useSelector((state: RootState) => state.reviewForm);

  const submitReviewForm = () => {
    setIsLoading(true);
    fetch(`/api/v1/recommend`, {
      headers: { "Content-Type": "application/json" },
      method: "PATCH",
      body: JSON.stringify({ comment, tags, email, episode_id }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (!!res.newRecommendation && res.newRecommendation.email !== null) {
          dispatch(
            updateEpisodeCommentsAndTags({
              podcastId,
              episodeId: episode_id,
              comment: res.newRecommendation.comment,
              tags: res.newRecommendation.tags,
            })
          );
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
        console.log("Error fetching: ", error);
      });
  };

  return { error, isLoading, submitReviewForm };
}
