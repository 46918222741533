import { Description, ExpandedDescription } from "./basic/Description";
import {
  EpisodeInterface,
  ID,
  PodcastInterface,
  TagInterface as Tag,
} from "../types";
import { TagCount, TagWithCount } from "./basic/Tag";
import {
  clearReviewForm,
  openReviewForm,
  updateReviewForm,
} from "../features/reviewFormSlice";
import { Card } from "./basic/Card";
import { LinkName } from "./basic/Name";
import { LoadingCircle } from "./basic/Loading";
import React from "react";
import { VerticalDivider } from "./basic/Bullet";
import { clearEpisodeFilter } from "../features/querySlice";
import itunesLogo from "../assets/images/itunes_logo.svg";
import spotifyLogo from "../assets/images/spotify_logo.png";
import styled from "styled-components";
import useDeleteRecommendation from "../effects/mutations/useDeleteRecommendation";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useRecommendEpisode from "../effects/mutations/useRecommendEpisode";

interface EpisodeProps {
  episode: EpisodeInterface;
  podcast: PodcastInterface;
  isLoggedIn: boolean;
  expanded?: boolean;
}

interface CounterProps {
  recommended: boolean;
}

const EpisodeCard = styled(Card)`
  padding-bottom: 0;
  margin: 2rem 0 3rem 0;
`;

const EpisodeHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  padding-bottom: 0.8rem;
`;

const EpisodeBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
`;

const HeaderRow = styled(Row)`
  padding-bottom: 0.8rem;
`;

const RowElement = styled.span`
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.olive};

  > .lnr {
    padding-right: 0.5rem;
    font-weight: 600;
  }
`;

const EpisodeName = styled(LinkName)`
  font-size: 1.2rem;
  font-family: "PT Serif", serif;
`;

const PodcastName = styled(RowElement)`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const CommentCount = styled(RowElement) <{ hasComments: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding-bottom: 1.5rem;
  color: ${(props) =>
    props.hasComments ? props.theme.colors.orange : props.theme.colors.olive};

  .lnr {
    font-size: 0.9rem;
    font-weight: 600;
  }

  p {
    margin: 0;
    font-weight: 600;
  }

  &:hover {
    cursor: pointer;

    > p {
      text-decoration: underline;
    }
  }
`;

const MissingDescription = styled(Description)`
  color: ${({ theme }) => theme.colors.olive};
  font-weight: 600;
`;

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
`;

const Count = styled.span<CounterProps>`
  background: ${(props) =>
    props.recommended ? props.theme.colors.orangeLight : "#f1f5f2"};
  font-weight: 600;
  width: 100%;
  text-align: center;
  border-radius: 0.3rem;
  padding: 0.3rem;
`;

const RecommendationCountWrapper = styled.div`
  padding: 0 1rem 0 2rem;
`;

const RecommendationCounter = styled.div<CounterProps>`
  user-select: none;
  font-weight: 600;

  color: ${(props) => (props.recommended ? "#ffa737" : "inherit")};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .lnr {
    font-size: 2rem;
    padding-bottom: 0.5rem;
  }

  &:hover {
    cursor: pointer;
    > .lnr {
      margin-top: -0.3rem;
      margin-bottom: 0.3rem;
      color: ${({ theme }) => theme.colors.orange}
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 0 0.2rem 0 1rem;
    .lnr {
      font-size: 1.8rem;
    }
  }
`;

const CommentList = styled.div`
  padding-bottom: 1.5rem;
`;

const Comment = styled.div`
  padding: 0.5rem;
  font-size: 0.9rem;
  border-radius: 0.3rem;
  background: ${({ theme }) => theme.colors.white};
  border-left: 3px solid #c2c7b8;
  margin-top: 1rem;
  line-height: 1.4;
  display: flex;

  .lnr-bubble {
    padding-right: 0.3rem;
  }
`;

const CurrentUserComment = styled(Comment)`
  background: #c9e1cd;
  border-color: ${({ theme }) => theme.colors.greenBright};
  display: flex;
  justify-content: space-between;
`;

const CurrentUserCommentBody = styled.div`
  padding-right: 1rem;
`;

const EditButton = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0 0.3rem;
  color: ${({ theme }) => theme.colors.greenBright};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const CardEditButton = styled(EditButton)`
  color: #feaf4d;
  padding-top: 1rem;

  @media only screen and (max-width: 768px) {
    padding: 1rem 0.2rem 0 1rem;
  }
`;

const EpisodeData = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 2rem;
`;

const PlatformLinks = styled.div`
  margin: 0 -1rem;
  background: #f1f5f2;
  padding: 1rem;
  border-radius: 0 0 0.3rem 0.3rem;
  display: flex;
  flex-wrap: wrap;

  // @media only screen and (max-width: 768px) {
  //   flex-direction: column;
  // }
`;

const PlatformLink = styled.a`
  margin-right: 1rem;
  height: 3rem;
  border-radius: 0.4rem;
  background: ${({ theme }) => theme.colors.whiteBright};
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px rgba(104, 112, 110, 0.35);
  -webkit-box-shadow: 0px 1px 2px 0px rgba(104, 112, 110, 0.35);

  @media only screen and (max-width: 768px) {
    height: 2.5rem;
  }
`;

const PlatformLogo = styled.img`
  height: 100%;
`;

const Label = styled.div`
  font-weight: 700;
`;

const PodcastRecommendationWrapper = styled.div`
  text-transform: uppercase;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.orangeDark};
  background: ${({ theme }) => theme.colors.orangeLight};
  border-radius: 1.2rem;
  padding: 0.2rem 0.5rem;
  display: flex;
  margin-left: -0.5rem;
  margin-right: auto;
`;

const PodcasterStar = styled.div`
  font-size: 0.8rem;
  margin-right: 0.5rem;

  .lnr {
    font-weight: 600;
  }
`;

const Episode: React.FunctionComponent<EpisodeProps> = ({
  episode,
  podcast,
  isLoggedIn,
  expanded = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const noCurrentUserComment = episode.currentUserComment === null;
  const { error, isLoading, recommendEpisode } = useRecommendEpisode(
    podcast.id,
    episode.id
  );
  const { deleteRecommendation } = useDeleteRecommendation({
    podcastId: podcast.id,
    episodeId: episode.id,
  });

  const handleRecommendEpisode = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (episode.currentUserRecommended) {
      if (
        window.confirm("Are you sure you want to remove this recommendation?")
      ) {
        deleteRecommendation();
        dispatch(clearReviewForm());
      } else {
        return;
      }
    } else {
      recommendEpisode();
      dispatch(
        openReviewForm({ episodeId: episode.id, podcastId: podcast.id })
      );
    }
  };

  const handleEditRecommendation = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    dispatch(
      updateReviewForm({
        comment: episode.currentUserComment ?? "",
        tags: episode.currentUserTags,
      })
    );
    dispatch(
      openReviewForm({
        episodeId: episode.id,
        podcastId: podcast.id,
        editing: true,
      })
    );
  };

  const navigateToPodcast = (
    e: React.MouseEvent<HTMLElement>,
    id: string | number
  ) => {
    e.stopPropagation();
    dispatch(clearEpisodeFilter());
    if (expanded) {
      history.push(`/podcast/${id}`);
    }
  };

  const navigateToEpisode = (episodeId: ID, podcastId: ID) => {
    if (!expanded) {
      history.push(`/podcast/${podcastId}/${episodeId}`);
    }
  };

  const handlePlatformLinkClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const renderCurrentUserComment = (currentUserComment: string | null) => {
    if (noCurrentUserComment) {
      return null;
    }

    return (
      <CurrentUserComment>
        <CurrentUserCommentBody>
          <span className="lnr lnr-bubble"></span> {currentUserComment}
        </CurrentUserCommentBody>
        {renderEditRecommendationButton(false)}
      </CurrentUserComment>
    );
  };

  const renderEditRecommendationButton = (recommendationCounter: boolean) => {
    if (!episode.currentUserRecommended || !isLoggedIn) {
      return null;
    }

    return recommendationCounter ? (
      <CardEditButton onClick={(e) => handleEditRecommendation(e)}>
        Edit
      </CardEditButton>
    ) : (
      <EditButton onClick={(e) => handleEditRecommendation(e)}>Edit</EditButton>
    );
  };

  const renderComments = () => {
    const currentUserCommentCount = episode.currentUserComment === null ? 0 : 1;
    const commentCount = episode.comments.length + currentUserCommentCount;
    const label = commentCount === 1 ? "comment" : "comments";
    const hasComments = commentCount > 0;

    if (!expanded) {
      return (
        <CommentCount
          hasComments={hasComments}
          onClick={() => navigateToEpisode(episode.id, episode.podcastId)}
        >
          <span className="lnr lnr-bubble"></span>
          <p>{`${commentCount} ${label}`}</p>
        </CommentCount>
      );
    }

    return (
      <>
        <CommentList>
          {renderCurrentUserComment(episode.currentUserComment)}
          {episode.comments.map((comment: string) => (
            <Comment key={comment}>
              <span className="lnr lnr-bubble"></span> {comment}
            </Comment>
          ))}
        </CommentList>
      </>
    );
  };

  const renderTagWithCount = (tag: Tag) => {
    return (
      <TagWithCount key={tag.id} color={tag.colorCode} selected={false}>
        {tag.name}
        <TagCount>{tag.count}</TagCount>
      </TagWithCount>
    );
  };

  const renderTags = (tags: Tag[]) => {
    const sortedTagCounts = getSortedTagCounts(tags);

    return (
      <TagWrapper>
        {sortedTagCounts.map((tag) => renderTagWithCount(tag))}
      </TagWrapper>
    );
  };

  const getSortedTagCounts = (tags: Tag[]) => {
    type TagCounts = { [index: string]: Tag };

    const tagCounts: TagCounts = {};
    const tagCountArray = [];

    tags.forEach((tag) => {
      if (tagCounts[tag.id] === undefined) {
        tagCounts[tag.id] = {
          ...tag,
          count: 0,
        };
      }

      tagCounts[tag.id].count++;
    });

    for (const tag in tagCounts) {
      tagCountArray.push(tagCounts[tag]);
    }

    return tagCountArray.sort((a, b) => b.count - a.count);
  };

  const renderEpisodeDuration = () => {
    const minutes = Math.round(episode.duration / 1000 / 60);
    const hours = Math.floor(minutes / 60);

    const hoursLabel = hours > 1 ? "hrs" : "hr";
    const hoursCount = hours > 0 ? `${hours} ${hoursLabel} ` : ``;
    const minutesCount = `${minutes - hours * 60} mins`;

    return `${hoursCount}${minutesCount}`;
  };

  const renderRecommendationCounter = () => {
    if (error) {
      console.log(error);
    }

    return isLoading ? (
      <LoadingCircle />
    ) : (
      <RecommendationCountWrapper>
        <RecommendationCounter
          recommended={episode.currentUserRecommended}
          onClick={(e) => handleRecommendEpisode(e)}
        >
            <span className="lnr lnr-diamond"></span>
          <Count recommended={episode.currentUserRecommended}>
            {episode.recommendations}
          </Count>
        </RecommendationCounter>
        {renderEditRecommendationButton(true)}
      </RecommendationCountWrapper>
    );
  };

  const renderDescription = () => {
    if (!episode.description.length) {
      return (
        <MissingDescription>
          Looks like this episode doesn&apos;t have a description.
        </MissingDescription>
      );
    }

    return expanded ? (
      <ExpandedDescription>{episode.description}</ExpandedDescription>
    ) : (
      <Description>{episode.description}</Description>
    );
  };

  const renderPodcastRecommendedStar = () => {
    if (!episode.podcasterRecommended) {
      return null;
    }

    return (
      <PodcastRecommendationWrapper>
        <PodcasterStar>
          <span className="lnr lnr-star"></span>
        </PodcasterStar>
        <Label>Recommended by podcaster!</Label>
      </PodcastRecommendationWrapper>
    );
  };

  const renderSpotifyButton = () => {
    if (episode.spotifyLink === null) {
      return null;
    }

    return (
      <PlatformLink
        href={episode.spotifyLink}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => handlePlatformLinkClick(e)}
      >
        <PlatformLogo src={spotifyLogo} alt="spotify logo" />
      </PlatformLink>
    );
  };

  const renderiTunesButton = () => {
    if (episode.itunesLink === null) {
      return null;
    }

    return (
      <PlatformLink
        href={episode.itunesLink}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => handlePlatformLinkClick(e)}
      >
        <PlatformLogo src={itunesLogo} alt="itunes logo" />
      </PlatformLink>
    );
  };

  return (
    <EpisodeCard key={episode.id}>
      <EpisodeHeaderWrapper>
        <HeaderRow>
          <EpisodeName
            onClick={() => navigateToEpisode(episode.id, episode.podcastId)}
          >
            {episode.name}
          </EpisodeName>
        </HeaderRow>
        <Row>
          <PodcastName onClick={(e) => navigateToPodcast(e, episode.podcastId)}>
            <strong>{podcast.name}</strong>
          </PodcastName>
        </Row>
        <Row>
          <RowElement>
            <span className="lnr lnr-calendar-full"></span>
            {episode.releaseDate}
          </RowElement>
          <VerticalDivider />
          <RowElement>
            <span className="lnr lnr-clock"></span>
            {renderEpisodeDuration()}
          </RowElement>
        </Row>

        {renderPodcastRecommendedStar()}
      </EpisodeHeaderWrapper>

      <EpisodeBodyWrapper>
        <EpisodeData>
          {renderDescription()}
          {renderRecommendationCounter()}
        </EpisodeData>
        {renderTags(episode.tags)}
      </EpisodeBodyWrapper>
      {renderComments()}
      <PlatformLinks>
        {renderSpotifyButton()}
        {renderiTunesButton()}
      </PlatformLinks>
    </EpisodeCard>
  );
};

export default Episode;
