import EpisodeList from "./EpisodeList";
import { Loading } from "./basic/Loading";
import { PodcastTitleCard } from "./Podcast";
import React from "react";
import ReviewForm from "./ReviewForm";
import { RootState } from "../store";
import { RouteComponentProps } from "react-router-dom";
import useGetEpisodesForPodcast from "../effects/useGetEpisodesForPodcast";
import useGetPodcastInfoById from "../effects/useGetPodcastInfoById";
import { useSelector } from "react-redux";

interface PodcastProps {
  podcastId: string;
}

export const PodcastPage: React.FunctionComponent<
  RouteComponentProps<PodcastProps>
> = ({ match }) => {
  const { podcastId } = match.params;

  const openReviewForm = useSelector((state: RootState) => {
    return state.reviewForm.podcastId?.toString() === podcastId.toString();
  });

  const {
    podcast,
    error: podcastError,
    isLoading: podcastIsLoading,
  } = useGetPodcastInfoById(podcastId);
  const {
    episodes,
    error: episodeError,
    isLoading: episodesAreLoading,
    moreEpisodesToLoad,
  } = useGetEpisodesForPodcast(podcastId);

  const renderErrorMessage = () => {
    return (
      <div>
        <h3>Whoops, looks like something went wrong on our end...</h3>
        <p>
          This podcast isn&apos;t working as expected. Sorry about that! Try
          searching for it again <a href="/">on the home page?</a>
        </p>
      </div>
    );
  };
  const renderMissingPodcastErrorMessage = () => {
    return (
      <div>
        <h3>404 Missing podcast</h3>
        <p>
          Looks like we don&apos;t have this podcast. Try searching for it again{" "}
          <a href="/">on the home page?</a>
        </p>
      </div>
    );
  };

  const renderTitleCard = () => {
    return podcastIsLoading ? <Loading /> : <PodcastTitleCard {...podcast} />;
  };

  const renderEpisodeList = () => {
    if (openReviewForm) {
      return <ReviewForm />;
    }
    return episodesAreLoading ? (
      <Loading />
    ) : (
      <EpisodeList
        episodes={episodes}
        podcast={podcast}
        moreEpisodesToLoad={moreEpisodesToLoad}
      />
    );
  };

  if (podcastError || episodeError) {
    return podcastError?.code === 404
      ? renderMissingPodcastErrorMessage()
      : renderErrorMessage();
  }

  return (
    <div>
      {renderTitleCard()}
      {renderEpisodeList()}
    </div>
  );
};
