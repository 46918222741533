import styled from "styled-components";

export const SubmitButton = styled.button`
  border: none;
  color: ${({ theme }) => theme.colors.whiteBright};
  background: ${({ theme }) => theme.colors.blue};
  padding: 0.5rem 2rem;
  border-radius: 0.3rem;
  margin: 1rem auto;
  display: block;
  box-shadow: 0px 1px 3px 2px rgb(179, 179, 179, 0.5);

  &:hover {
    cursor: pointer;
  }
`;
