import styled from "styled-components";

export const Name = styled.p`
  font-weight: 700;
  font-size: 0.9rem;
  margin: 0;
  padding-bottom: 0.2rem;
`;

export const LinkName = styled.a`
  font-weight: 700;
  font-size: 0.9rem;
  margin: 0;
  padding-bottom: 0.2rem;
  color: #000000;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
