import { EpisodeInterface, ID } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../store";
import { addEpisode } from "../features/episodesSlice";

interface episodeEffectInterface {
  episode: EpisodeInterface | undefined;
  error: string | null;
  isLoading: boolean;
}

export default function useGetEpisodeInfoById(
  podcastId: ID,
  episodeId: ID
): episodeEffectInterface {
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // TODO: standardize how we check loading & data from redux on each effect/slice

  const matchingEpisodeForThisId = useSelector((state: RootState) => {
    return state.episodes[Number(podcastId)]?.find((episode) => {
      return Number(episode.id) === Number(episodeId);
    });
  });

  useEffect(() => {
    if (matchingEpisodeForThisId === undefined) {
      setIsLoading(true);
      fetch(`/api/v1/episode_info/${episodeId}`)
        .then((res) => res.json())
        .then((res) => {
          if (res === null) {
            setError("couldn't find an episode for this ID" as const);
          } else {
            dispatch(addEpisode({ episode: res }));
          }

          setIsLoading(false);
        })
        .catch((error) => {
          setError(error);
          console.log("Error fetching: ", error);
        });
    } else {
      setIsLoading(false);
    }
  }, [matchingEpisodeForThisId]);

  return { isLoading, error, episode: matchingEpisodeForThisId };
}
