import { Card } from "./basic/Card";
import React from "react";
import styled from "styled-components";

const Navigator = styled.div`
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
`;

const NavigationButton = styled.button`
  border: none;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  font-size: 0.9rem;
  border-radius: 0.3rem;
  background: ${({ theme }) => theme.colors.whiteBright};

  .pushed-left {
    margin-left: -8px;
  }

  .pushed-right {
    margin-right: -8px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const LeftNavigationButton = styled(NavigationButton)`
  padding: 0.4rem 0.4rem 0.3rem 0.4rem;
  margin-right: 0.4em;
`;

const RightNavigationButton = styled(NavigationButton)`
  padding: 0.4rem 0.4rem 0.3rem 0.4rem;
  margin-left: 0.4em;
`;

const ButtonGroup = styled.div``;

type NavigatorProps = {
  currentIndex: number;
  episodesPerPage: number;
  totalEpisodes: number;
  isSecondary: boolean;
  handlePageUp: () => void;
  handlePageDown: () => void;
  handleFirstPage: () => void;
  handleLastPage: () => void;
};

export const EpisodeNavigator: React.FunctionComponent<NavigatorProps> = ({
  currentIndex = 0,
  totalEpisodes,
  episodesPerPage,
  isSecondary,
  handlePageUp,
  handlePageDown,
  handleFirstPage,
  handleLastPage,
}) => {
  const nextIndex = currentIndex + episodesPerPage;

  if (totalEpisodes === 0) {
    if (isSecondary) {
      return null;
    } else {
      return (
        <Card>
          <h3>There are no matching episodes.</h3>
        </Card>
      );
    }
  }

  return (
    <Navigator>
      <ButtonGroup>
        <LeftNavigationButton onClick={() => handleFirstPage()}>
          <span className="lnr lnr-chevron-left"></span>
          <span className="lnr lnr-chevron-left pushed-left"></span>
        </LeftNavigationButton>
        <NavigationButton onClick={() => handlePageDown()}>
          <span className="lnr lnr-chevron-left"></span>
        </NavigationButton>
      </ButtonGroup>
      <span>
        Episodes {currentIndex + 1} to{" "}
        {nextIndex > totalEpisodes ? totalEpisodes : nextIndex}
      </span>
      <ButtonGroup>
        <NavigationButton onClick={() => handlePageUp()}>
          <span className="lnr lnr-chevron-right"></span>
        </NavigationButton>
        <RightNavigationButton onClick={() => handleLastPage()}>
          <span className="lnr lnr-chevron-right pushed-right"></span>
          <span className="lnr lnr-chevron-right"></span>
        </RightNavigationButton>
      </ButtonGroup>
    </Navigator>
  );
};
