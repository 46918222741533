import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TagInterface } from "../types";

const initialState: TagInterface[] = [];

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    saveTags: (state, action: PayloadAction<TagInterface[]>) => {
      // we can either set one state property to payload, or return a whole new object.
      return [...state, ...action.payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveTags } = tagsSlice.actions;

export default tagsSlice.reducer;
