import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../store";
import { TrendingEpisodeInterface } from "../types";
import { addTrendingEpisodes } from "../features/trendingEpisodesSlice";

interface trendingEpisodeEffectInterface {
  trendingEpisodes: TrendingEpisodeInterface[] | [];
  error: string | null;
  isLoading: boolean;
}

export default function useTrendingEpisodes(): trendingEpisodeEffectInterface {
  const dispatch = useDispatch();
  const trendingEpisodes =
    useSelector((state: RootState) => state.trendingEpisodes.episodes) ?? [];

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // TODO: standardize how we check loading & data from redux on each effect/slice

  useEffect(() => {
    if (!trendingEpisodes.length) {
      fetch(`/api/v1/trending_episodes`)
        .then((res) => res.json())
        .then((res) => {
          dispatch(addTrendingEpisodes(res.episodes));
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error);
          console.log("Error fetching: ", error);
        });
    } else {
      setIsLoading(false);
    }
  }, [trendingEpisodes.length]);

  return {
    isLoading,
    error,
    trendingEpisodes,
  };
}
