import { ID, PodcastInterface } from "../types";
import { Description } from "./basic/Description";
import { Name } from "./basic/Name";
import React from "react";
import appleIcon from "../assets/images/apple_icon.png";
import { clearEpisodeFilter } from "../features/querySlice";
import spotifyIcon from "../assets/images/spotify_icon.png";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const PodcastCard = styled.div`
  display: flex;
  margin-bottom: 2rem;
  border-radius: 0.2rem;

  &:hover {
    cursor: pointer;
  }
`;

const PodcastName = styled(Name)`
  font-size: 1.4rem;
  font-family: "PT Serif", serif;
  padding-bottom: 0.5rem;

  &:hover {
    text-decoration: underline;
  }
`;

const PodcastInfo = styled.div`
  box-sizing: border-box;
  overflow-y: scroll;
  max-height: 300px;
`;

const LargePodcastCard = styled(PodcastCard)`
  margin-bottom: 4rem;
  &:hover {
    cursor: initial;
  }

  ${PodcastInfo} {
    padding: 0;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 0rem;
  }
`;

const Title = styled(Name)`
  font-size: 1.8rem;
  padding-bottom: 0.5rem;
`;

const ExpandedDescription = styled(Description)`
  display: block;
  -webkit-line-clamp: initial;
`;

const LinkImage = styled.img`
  max-width: 128px;
  width: 25%;
  border-radius: 0.3rem;
  margin-right: 1rem;
  align-self: flex-start;

  &:hover {
    cursor: pointer;
  }
`;
const LinkTitle = styled(Title)`
  font-family: "PT Serif", serif;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const MetadataItem = styled(Name)`
  text-transform: uppercase;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.olive};
  padding: 0;
  padding-right: 0.5rem;
  font-weight: 700; ;
`;

const PodcastMetadata = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const PlatformIcon = styled.img`
  height: 1.3rem;
  width: 1.3rem;
`;

export const Podcast: React.FunctionComponent<PodcastInterface> = (podcast) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const navigateToPage = (id: ID) => {
    dispatch(clearEpisodeFilter());
    history.push(`/podcast/${id}`);
  };

  const episodeCount = () =>
    Math.max(podcast.totalSpotifyEpisodes, podcast.totalItunesEpisodes);
  const renderItunesBadge = () => {
    if (!podcast.itunesId) {
      return null;
    }
    return <PlatformIcon src={appleIcon} alt="apple icon" />;
  };

  const renderSpotifyBadge = () => {
    if (!podcast.spotifyId) {
      return null;
    }
    return <PlatformIcon src={spotifyIcon} alt="spotify icon" />;
  };

  return (
    <PodcastCard key={podcast.id} onClick={() => navigateToPage(podcast.id)}>
      <LinkImage src={podcast.image} alt="podcast image" />
      <PodcastInfo>
        <PodcastName>{podcast.name}</PodcastName>
        <PodcastMetadata>
          <MetadataItem>{podcast.artist} |</MetadataItem>
          <MetadataItem onClick={() => navigateToPage(podcast.id)}>
            {episodeCount()} episodes |
          </MetadataItem>
          {renderSpotifyBadge()}
          {renderItunesBadge()}
        </PodcastMetadata>
        <Description>{podcast.description}</Description>
      </PodcastInfo>
    </PodcastCard>
  );
};

export const PodcastTitleCard: React.FunctionComponent<PodcastInterface> = (
  podcast
) => {
  const history = useHistory();

  const navigateToPage = (id: ID) => {
    history.push(`/podcast/${id}`);
  };

  // TODO: this duplicates a lot of code from above, and the metadata should live in its own component
  const episodeCount = () =>
    Math.max(
      podcast.totalSpotifyEpisodes ?? 0,
      podcast.totalItunesEpisodes ?? 0
    );
  const renderItunesBadge = () => {
    if (!podcast.itunesId) {
      return null;
    }
    return <PlatformIcon src={appleIcon} alt="apple icon" />;
  };

  const renderSpotifyBadge = () => {
    if (!podcast.spotifyId) {
      return null;
    }
    return <PlatformIcon src={spotifyIcon} alt="spotify icon" />;
  };

  return (
    <LargePodcastCard>
      <LinkImage
        src={podcast.image}
        alt="podcast image"
        onClick={() => navigateToPage(podcast.id)}
      />
      <PodcastInfo>
        <LinkTitle onClick={() => navigateToPage(podcast.id)}>
          {podcast.name}
        </LinkTitle>
        <PodcastMetadata>
          <MetadataItem>{podcast.artist} |</MetadataItem>
          <MetadataItem onClick={() => navigateToPage(podcast.id)}>
            {episodeCount()} episodes |
          </MetadataItem>
          {renderSpotifyBadge()}
          {renderItunesBadge()}
        </PodcastMetadata>
        <ExpandedDescription>{podcast.description}</ExpandedDescription>
      </PodcastInfo>
    </LargePodcastCard>
  );
};
