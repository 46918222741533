import styled from "styled-components";

export const Card = styled.div`
  background: ${({ theme }) => theme.colors.whiteBright};
  box-shadow: ${({ theme }) => theme.boxShadow.standard};
  padding: 1rem;
  border-radius: 0.3rem;
  box-sizing: border-box;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    margin: 0;
  }
`;

export const ErrorCard = styled(Card)`
  padding: 2rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    color: ${({ theme }) => theme.colors.redLight};
  }

  p {
    line-height: 1.6;
  }
`;
