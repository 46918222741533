import { Card } from "./basic/Card";
import { Loading } from "./basic/Loading";
import React from "react";
import { RootState } from "../store";
import { UserAdminInterface } from "../types";
import styled from "styled-components";
import useAdminData from "../effects/useAdminData";
import { useSelector } from "react-redux";

const Field = styled.span`
  line-height: 1.6;
  padding-right: 1rem;
  min-width: 250px;
`;

const SecondaryField = styled(Field)`
  color: ${({ theme }) => theme.colors.green};
`;

const CommentCard = styled(Card)`
  margin-bottom: 1rem;
`;

const InfoRow = styled.div`
  display: flex;
  margin-bottom: 1rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

interface CommentInterface {
  comment: string;
  author: string;
  createdAt: string;
  confirmed: boolean;
  episodeName: string;
  podcastName: string;
  episodeId: string | number;
  podcastId: string | number;
}

interface EpisodeRecommendationInterface {
  [key: string]: string;
}

const Admin: React.FunctionComponent = () => {
  const { data, error, isLoading } = useAdminData();
  const admin = useSelector((state: RootState) => state.session.user?.admin);

  if (!admin) {
    window.location.href = "/";
  }

  if (error) {
    console.log(error);
  }

  if (isLoading) {
    return <Loading />;
  }

  const {
    users,
    recCount,
    comments,
    confirmedCommentCount,
    recommendedEpisodes,
  } = data;

  const renderDate = (date: string) => {
    const createdAtDate = new Date(date).toString().substr(0, 21);
    return createdAtDate;
  };

  const renderUser = (user: UserAdminInterface) => {
    return (
      <InfoRow key={user.email}>
        <Field>{renderDate(user.createdAt)}</Field>
        <Field>{user.email}</Field>
      </InfoRow>
    );
  };

  const renderComment = (comment: CommentInterface) => {
    return (
      <CommentCard key={comment.createdAt}>
        <SecondaryField>
          <strong>{comment.author}</strong> on {renderDate(comment.createdAt)}
        </SecondaryField>
        <SecondaryField>
          <a href={`podcast/${comment.podcastId}/${comment.episodeId}`}>
            {comment.episodeName}
          </a>{" "}
          | <strong>{comment.podcastName}</strong>
        </SecondaryField>
        <Field>{comment.comment}</Field>
      </CommentCard>
    );
  };

  const renderRecommendation = (episode: EpisodeRecommendationInterface) => {
    return (
      <div>
        <CommentCard key={episode.createdAt}>
          <SecondaryField>
            {" "}
            {renderDate(episode.createdAt)} by <strong>{episode.author}</strong>
          </SecondaryField>
          <SecondaryField>
            {episode.episodeName} | {episode.podcastName}
          </SecondaryField>
        </CommentCard>
      </div>
    );
  };

  const renderCommenPercentage = () => {
    return Math.floor((comments?.length / recCount) * 100);
  };

  const renderConfirmedCommenPercentage = () => {
    return Math.floor((confirmedCommentCount / comments?.length) * 100);
  };

  const renderRecData = () => {
    return (
      <div>
        <div>
          <strong>{comments?.length}</strong> comments (
          {renderCommenPercentage()}%)
        </div>
        <div>
          <strong>{confirmedCommentCount}</strong> confirmed (
          {renderConfirmedCommenPercentage()}%)
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2>
        Recommendations: <strong>{recCount}</strong>
      </h2>
      {renderRecData()}

      <h2>
        Users: <strong>{users?.length ?? 0}</strong>
      </h2>
      {users?.map((user: UserAdminInterface) => renderUser(user))}

      <h2>Last 10 Recommendations:</h2>
      {recommendedEpisodes?.map((episode: EpisodeRecommendationInterface) =>
        renderRecommendation(episode)
      )}

      <h2>Comments:</h2>
      {comments?.map((comment: CommentInterface) => renderComment(comment))}
    </div>
  );
};

export default Admin;
