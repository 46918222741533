import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../store";
import { SessionInterface } from "../types";
import { saveSession } from "../features/sessionSlice";

interface SessionEffectInterface {
  session: SessionInterface;
  error: string | null;
  isLoading: boolean;
}

export default function useSession(): SessionEffectInterface {
  const dispatch = useDispatch();
  const session = useSelector((state: RootState) => state.session);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // TODO: standardize how we check loading & data from redux on each effect/slice

  useEffect(() => {
    if (!session.fetched) {
      fetch(`/api/v1/session`)
        .then((res) => res.json())
        .then((res) => {
          setIsLoading(false);
          dispatch(saveSession({ ...res, fetched: true }));
        })
        .catch((error) => {
          setError(error);
          console.log("Error fetching: ", error);
        });
    } else {
      setIsLoading(false);
    }
  }, [session.fetched]);

  return {
    isLoading,
    error,
    session,
  };
}
