import {
  clearEpisodeCommentsAndTags,
  updateEpisodeRecommendationCount,
} from "../../features/episodesSlice";
import { useDispatch, useSelector } from "react-redux";
import { ID } from "../../types";
import { clearReviewForm } from "../../features/reviewFormSlice";
import { useState } from "react";

interface useDeleteRecommendation {
  error: string | null;

  isLoading: boolean;
  deleteRecommendation: () => void;
}
export default function useDeleteRecommendation(props: {
  episodeId: ID;
  podcastId: ID;
}): useDeleteRecommendation {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { episodeId, podcastId } = props;

  const deleteRecommendation = () => {
    setIsLoading(true);
    fetch(`/api/v1/recommend`, {
      headers: { "Content-Type": "application/json" },
      method: "DELETE",
      body: JSON.stringify({ episode_id: episodeId }),
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(clearEpisodeCommentsAndTags({ podcastId, episodeId }));
        dispatch(clearReviewForm());

        if (podcastId !== undefined && episodeId !== undefined) {
          dispatch(
            updateEpisodeRecommendationCount({
              podcastId,
              episodeId,
              change: res.change,
            })
          );
        }
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
        console.log("Error fetching: ", error);
      });
  };

  return { error, isLoading, deleteRecommendation };
}
