import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface QueryState {
  podcastQuery: string;
  episodeFilter: string;
  lastSearchTimestamp: number;
}

const initialState: QueryState = {
  podcastQuery: "",
  episodeFilter: "",
  lastSearchTimestamp: Date.now(),
};

export const querySlice = createSlice({
  name: "queries",
  initialState,
  reducers: {
    updatePodcastQuery: (state, action: PayloadAction<string>) => {
      state.podcastQuery = action.payload;
    },
    updatePodcastSearchTimestamp: (state, action: PayloadAction<number>) => {
      state.lastSearchTimestamp = action.payload;
    },
    updateEpisodeFilter: (state, action: PayloadAction<string>) => {
      state.episodeFilter = action.payload;
    },
    clearPodcastQuery: (state) => {
      state.podcastQuery = "";
      return state;
    },
    clearEpisodeFilter: (state) => {
      state.episodeFilter = "";
      return state;
    },
  },
});

export const {
  updatePodcastQuery,
  updateEpisodeFilter,
  updatePodcastSearchTimestamp,
  clearPodcastQuery,
  clearEpisodeFilter,
} = querySlice.actions;

export default querySlice.reducer;
