import { EpisodeInterface, EpisodeSliceInterface } from "../types";
import { ID, TagInterface } from "../types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: EpisodeSliceInterface = {};

export const episodesSlice = createSlice({
  name: "episodes",
  initialState,
  reducers: {
    addEpisodes: (
      state,
      action: PayloadAction<{
        episodes: EpisodeInterface[];
        podcastId: string | number;
      }>
    ) => {
      const podcastId = Number(action.payload.podcastId);

      if (state[podcastId] === undefined) {
        state[podcastId] = action.payload.episodes;
      } else {
        const currentEpisodeIds = state[podcastId].map((episode) => episode.id);
        const newEpisodesToAdd = action.payload.episodes.filter((episode) => {
          return !currentEpisodeIds.includes(episode.id);
        });

        state[podcastId] = [...state[podcastId], ...newEpisodesToAdd];
      }

      return state;
    },
    // TODO: do we need both `addEpisode` and `addEpisodes` ?
    addEpisode: (
      state,
      action: PayloadAction<{
        episode: EpisodeInterface;
      }>
    ) => {
      const podcastId = Number(action.payload.episode.podcastId);

      if (state[podcastId] === undefined) {
        state[podcastId] = [action.payload.episode];
      } else if (
        state[podcastId].filter((e) => e.id === action.payload.episode.id)
          .length === 0
      ) {
        state[podcastId] = [...state[podcastId], action.payload.episode];
      }

      return state;
    },
    updateEpisodeRecommendationCount: (
      state,
      action: PayloadAction<{
        episodeId: ID;
        podcastId: ID;
        change: number;
      }>
    ) => {
      const { episodeId, podcastId, change } = action.payload;
      const podcastEpisodes = state[Number(podcastId)];
      const episode = podcastEpisodes.find((e) => {
        return e.id == episodeId;
      });

      if (episode !== undefined) {
        episode.recommendations += change;
        episode.currentUserRecommended = !episode.currentUserRecommended;
      }

      return state;
    },

    updateEpisodeCommentsAndTags: (
      state,
      action: PayloadAction<{
        episodeId?: string | number;
        podcastId?: string | number;
        comment: string | null;
        tags: TagInterface[];
      }>
    ) => {
      const { episodeId, podcastId, tags, comment } = action.payload;
      const podcastEpisodes = state[Number(podcastId)];
      const episode = podcastEpisodes.find((e) => {
        return e.id == episodeId;
      });

      // TODO: this is duplicate code from above

      if (episode !== undefined) {
        episode.tags = tags;
        episode.currentUserComment = comment;
      }

      return state;
    },

    clearEpisodeCommentsAndTags: (
      state,
      action: PayloadAction<{
        episodeId?: string | number;
        podcastId?: string | number;
      }>
    ) => {
      const { episodeId, podcastId } = action.payload;
      const podcastEpisodes = state[Number(podcastId)];
      const episode = podcastEpisodes.find((e) => {
        return e.id == episodeId;
      });

      // TODO: this is duplicate code from above

      if (episode !== undefined) {
        episode.currentUserTags = [];
        episode.currentUserComment = null;
      }

      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addEpisodes,
  addEpisode,
  updateEpisodeRecommendationCount,
  updateEpisodeCommentsAndTags,
  clearEpisodeCommentsAndTags,
} = episodesSlice.actions;

export default episodesSlice.reducer;
